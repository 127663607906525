import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { NbToastrService } from '@nebular/theme';

import { MediaEntity } from '@loyx/common';

import { MediasService } from '../medias.service';

@Component({
  selector: 'media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
})
export class MediaUploadComponent {
  @Output() uploadedImageOutput = new EventEmitter<MediaEntity>();

  @Input() multiUpload: boolean = false;

  @ViewChild('fileInput') fileInput;

  file: File[] | null = [];
  uploading: boolean = false;
  uploadProgress;
  uploadFailed: boolean = false;
  uploadComplete: boolean = false;
  uploadedImages = [];
  selectedImage = null;

  constructor(private mediasService: MediasService, private toastrService: NbToastrService) {}

  fileInputSelected(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    for (let i = 0; i < files.length; i++) {
      this.file[i] = files[i] ? files[i] : null;
    }
    this.uploading = false;
  }

  async uploadFile() {
    if (!this.file) return;

    this.uploading = true;
    this.uploadedImages = [];
    this.mediasService.uploadMedia(this.file.reverse()).subscribe({
      next: (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round((100 * event.loaded) / event.total);
        } else if (event.type === HttpEventType.ResponseHeader) {
          if (event.status != 201) {
            this.uploading = false;
            this.uploadFailed = true;
          }
        } else if (event instanceof HttpResponse) {
          const res: any = event.body;
          this.file = [];
          this.uploading = false;
          this.uploadComplete = true;
          this.uploadFailed = false;

          res.data.forEach((uploadedImage) => {
            this.uploadedImages.push(uploadedImage);
          });
          this.selectedImage = this.uploadedImages;
          if (this.multiUpload) this.uploadedImageOutput.emit(this.selectedImage);
          else this.uploadedImageOutput.emit(this.selectedImage[0]);
          this.fileInput.nativeElement.value = '';
          this.toastrService.success('Risorsa caricata con successo', 'Yeah!', { duration: 3000 });
        }
      },
    });
  }
}
