import { IMongodbDocument } from './mongodb';

import {
  EGiftCardTransactionSource,
  EGiftCardTransactionStatus,
  EGiftCardTransactionType,
} from '../constants';
import { IApiToken } from './api-tokens';
import { IGiftCard } from './giftcards';
import { IOrganizationEntityDocument } from './organizations';
import { IUser } from './users';
import { IStore } from './stores';

export interface IGiftCardTransaction extends IMongodbDocument, IOrganizationEntityDocument {
  giftCardId: string;
  giftCard?: IGiftCard;
  amount: number;
  source: EGiftCardTransactionSource;
  type: EGiftCardTransactionType;
  status?: EGiftCardTransactionStatus;
  notes?: string;
  purchaseInfo?: IGiftCardTransactionPurchaseDocument;
  userId?: string;
  user?: IUser;
  apiTokenId?: string;
  apiToken?: IApiToken;
  storeId?: string;
  store?: IStore;
}

export interface IGiftCardTransactionPurchaseDocument {
  /** Data del documento */
  date?: string;
  /** Codice gestionale del riferimento documento */
  reference?: string;
  /** Numero gestionale del documento */
  number?: string;
  /** Importo intero dell'acquisto */
  amount?: number;
  /** Note aggiuntive documento */
  note?: string;
}
