import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    constructor(
        private toastr: NbToastrService,
    ) {

    }

    /**
     * 
     * @param text 
     */
    copyTextToClipboard(text: string, toastOpt?: { message?: string; title?: string }) {
        const textArea = document.createElement("textarea");
        textArea.textContent = text;

        const body = document.getElementsByTagName('body')[0];
        body.appendChild(textArea);

        textArea.select();

        document.execCommand('copy');

        body.removeChild(textArea);

        this.toastr.success(toastOpt?.message || `Testo copiato negli appunti!`, toastOpt?.title || `Yeah`);
    }
}
