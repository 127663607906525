import { IMongodbDocument } from './mongodb';

import { ECampaignRewardType, ECampaignStatus } from '../constants';
import { IAgreement } from './agreements';
import { ICardType } from './cards';
import { IOrganizationEntityDocument } from './organizations';
import { IReward } from './rewards';
import { IMedia } from './medias';
import { IUser } from './users';

export interface ICampaign extends IMongodbDocument, IOrganizationEntityDocument {
  /** Campagna attivata/disattivata  */
  active: boolean;
  /** Nome campagna  */
  name: string;
  /** Descrizione pubblica campagna  */
  description: string;
  /** Data inizio campagna  */
  startDate: string;
  /** Data fine campagna  */
  endDate: string;
  /** Data ultima per riscattare premi */
  rewardRequestMaxData: string;
  /** Tipi di carte per cui è attiva la campagna  */
  cardTypesIds: string[];
  cardTypes?: ICardType[];
  /** Stato campagna  */
  status?: ECampaignStatus;
  /** Punti assegnati  */
  points?: number;
  /** Valore dei punti  */
  pointsValue?: number;
  /** Importo minimo per aggiungere punti  */
  minimumAmount?: number;
  /** Punti al raggiungimento dell'importo minimo */
  minimumAmountPoints?: number;
  /** Bonus attivo/non attivo */
  bonusActive?: boolean;
  /** Importo minimo per aggiungere punti bonus */
  bonusAmount?: number;
  /** Bonus punti al raggiungimento dell'importo minimo  */
  bonusPoints?: number;
  /** Bonus di benvenuto ad inizio campagna (o alla prima operazione)  */
  welcomePoints?: number;
  /** Regolamento Campagna */
  termsAgreementId?: string;
  termsAgreement?: IAgreement;
  /** Utente che ha creato la card */
  createdByUserId?: string;
  createdByUser?: IUser;

  rewards?: IReward[] | null | string;

  mediaId?: string;
  media?: IMedia;

  /** Regole */
  // rules?: {
  //     triggers: any;
  //     conditions: any;
  //     results: any;
  // }[];
}

export interface ICampaignReward extends IMongodbDocument {
  // campaign: ICampaign;
  type: ECampaignRewardType;
  description: string;
  // sku: string;
  pointsThreshold: number;
  amountThreshold: number;
  couponValue: number;
}
