import { Pipe, PipeTransform } from '@angular/core';

import { CardTransactionStatus, ECardTransactionStatus } from '@loyx/common';

@Pipe({
  name: 'cardTransactionStatus',
})
export class CardTransactionStatusPipe implements PipeTransform {
  transform(statusId: ECardTransactionStatus | string, ...args: unknown[]): unknown {
    if (!statusId) return '';

    const status = CardTransactionStatus.find((u) => u.id === statusId);

    return status ? status.description : statusId;
  }
}
