import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IConsent } from '@loyx/common';

@Component({
  selector: 'si-consents-item-list',
  templateUrl: './consents-item-list.component.html',
  styleUrls: ['./consents-item-list.component.scss'],
})
export class ConsentsItemListComponent {
  @Input('consents') consent: IConsent;
  @Output() onRefuse: EventEmitter<IConsent> = new EventEmitter<IConsent>();

  constructor() { }
}
