import {
  EGiftCardTransactionSource,
  EGiftCardTransactionStatus,
  EGiftCardTransactionType,
} from '../constants';
import { ApiTokenEntity } from './api-token.entity';
import { DbOrganizationResourceEntity } from './db.entity';
import { GiftCardEntity } from './giftcard.entity';
import { StoreEntity } from './store.entity';
import { UserEntity } from './user.entity';

export class GiftCardTransactionEntity extends DbOrganizationResourceEntity<GiftCardTransactionEntity> {
  /** Gift Card */
  giftCardId: string;
  /** Importo Transazione - Obbligatorio se del tipo PURCHASE */
  amount: number;
  /** Sorgente da cui è partita la transazione  */
  source: EGiftCardTransactionSource;
  /** Tipo della transazione  */
  type: EGiftCardTransactionType;
  /** Stato della transazione */
  status: EGiftCardTransactionStatus;
  /** Note informative aggiuntive transazione */
  notes?: string;
  /** Eventuali informazioni del documento di acquisto per cui si è utilizzata la gift card */
  purchaseInfo?: GiftCardTransactionPurchaseInfoEntity | string;
  /** Utente backoffice che ha effettuato la transazione */
  userId?: string;
  /** Token api integrazione che ha effettuato la transazione */
  apiTokenId?: string;
  /** Negozio nel quale è stato effettuato l'acquisto */
  storeId?: string;

  readonly user?: UserEntity;
  readonly apiToken?: ApiTokenEntity;
  readonly giftCard?: GiftCardEntity;
  readonly store?: StoreEntity;
}

export class GiftCardTransactionPurchaseInfoEntity {
  /** Data del documento */
  date?: string;
  /** Codice gestionale del riferimento documento */
  reference?: string;
  /** Numero gestionale del documento */
  number?: string;
  /** Importo intero dell'acquisto */
  amount?: number;
  /** Note aggiuntive documento */
  note?: string;
}
