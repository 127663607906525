import { DbOrganizationResourceEntity, DbResourceEntity } from './db.entity';
import { ECardSummaryMovementTrigger, ECardSummaryMovementType } from '../constants';
import { CampaignEntity } from './campaign.entity';
import { CardTransactionEntity, PurchaseDocumentEntity } from './card-transaction.entity';
import { CardEntity } from './card.entity';
import { StoreEntity } from './store.entity';
import { UserEntity } from './user.entity';
import { RewardEntity } from './rewards.entity';

export class CardSummaryEntity extends DbOrganizationResourceEntity<CardSummaryEntity> {
  /** Carta */
  cardId: string;
  /** Campagna di riferimento  */
  campaignId: string;
  /** Importo speso nella campagna di riferimento */
  purchasesAmount?: number;
  /** Saldo punti campagna di riferimento  */
  points?: number;
  /** Movimenti carta della campagna di riferimento */
  movements?: CardSummaryMovementEntity[];

  readonly campaign?: CampaignEntity; //MongoIdOrDocument<ICampaign>;
  readonly card?: CardEntity;
}

export type CardSummaryWritableEntity = Omit<CardSummaryEntity, 'campaign' | 'card'> & {
  /** Carta */
  cardId: string;
  /** Campagna di riferimento  */
  campaignId: string;
  /** Importo speso nella campagna di riferimento */
  purchasesAmount?: number;
  /** Saldo punti campagna di riferimento  */
  points?: number;
  /** Movimenti carta della campagna di riferimento */
  movements?: CardSummaryMovementEntity[];
  campaign: CampaignEntity;
  readonly card?: CardEntity;
};

export class CardSummaryMovementEntity extends DbResourceEntity {
  /** tipo di trigger che ha fatto partire il movimento */
  trigger: ECardSummaryMovementTrigger;
  /** Tipo del movimento */
  type: ECardSummaryMovementType;
  /** Punti */
  points?: number;
  /** Note */
  note?: string;
  /** Eventuale negozio di riferimento */
  storeId?: string;
  /** Eventuale transazione carta */
  transactionId?: string;
  /** Eventuale importo acquisto */
  purchaseAmount?: number;
  /** Eventuali informazioni documento di acquisto */
  document?: PurchaseDocumentEntity;
  /** Eventuale utente backoffice che ha effettuato il movimento */
  userId?: string;

  rewardId?: string;

  /** Eventuale evento */
  // event?: ICardEvent;
  /** Richiesta/Ordine premio catalogo campagna */
  // rewardRequest?: ICampaignRewardRequest;
  // order?: IRewardOrder;

  readonly store?: StoreEntity;
  readonly transaction?: CardTransactionEntity;
  readonly user?: UserEntity;
  readonly reward?: RewardEntity;
}
