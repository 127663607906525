import { Injectable } from '@angular/core';
import { ApiBackendBaseService } from '../../@core/utils/api-backend-base.service';
import { IMedia } from '@loyx/common';

@Injectable({
  providedIn: 'root',
})
export class MediasService extends ApiBackendBaseService<IMedia> {
  override basePath = ':organization/medias';

  uploadMedia(mediaFile: File[]) {
    const formData = new FormData();
    for (let i = 0; i < mediaFile.length; i++) {
      formData.append('files', mediaFile[i]);
    }

    return this.backend.uploadFile(this.getPath(), formData);
  }
}
