import { DbOrganizationResourceEntity } from './db.entity';
import { UserEntity } from './user.entity';

export class MediaEntity extends DbOrganizationResourceEntity<MediaEntity> {
  override _id?: string;
  bucketName: string;
  filePath: string;
  contentType: string;
  name?: string;
  description?: string;
  size?: number;
  createdByUserId?: string;
  type?: string;

  readonly createdByUser?: UserEntity;
  readonly url?: string;
}
