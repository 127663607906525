<nb-form-field *ngIf="storeIdFormControl" [nbSpinner]="loadingStores">
  <nb-icon nbPrefix icon="home-outline" pack="eva"></nb-icon>

  <input
    #storeFilterInput
    nbInput
    type="text"
    fullWidth
    placeholder="Cerca negozio"
    (input)="onChange($event)"
    (focusout)="setStoreIdStatus()"
    [nbAutocomplete]="acStores"
    [status]="storeIdFormControl.touched && storeIdFormControl.invalid ? 'danger' : 'basic'"
  />
  <button type="button" nbSuffix nbButton ghost (click)="clearInput($event)">
    <nb-icon [icon]="'close-outline'" pack="eva"> </nb-icon>
  </button>

  <nb-autocomplete #acStores (selectedChange)="onSelectionChange($event)">
    <nb-option *ngFor="let i of filteredStores" [value]="i._id">{{ i.description }}</nb-option>
  </nb-autocomplete>
</nb-form-field>

<nb-form-field *ngIf="!storeIdFormControl">
  <input
    #storeFilterInput
    nbInput
    type="text"
    fieldSize="small"
    fullWidth
    placeholder="Cerca negozio"
    (input)="onChange()"
    [nbAutocomplete]="acStores"
  />

  <button type="button" nbSuffix nbButton ghost (click)="clearInput($event)">
    <nb-icon [icon]="'close-outline'" pack="eva"> </nb-icon>
  </button>

  <nb-autocomplete #acStores (selectedChange)="onSelectionChange($event)">
    <nb-option *ngFor="let i of filteredStores" [value]="i._id">{{ i.description }}</nb-option>
  </nb-autocomplete>
</nb-form-field>
