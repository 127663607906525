import { Pipe, PipeTransform } from '@angular/core';
import { ECustomerGender } from '@loyx/common';

const GENDERS_DESCRIPTIONS = {
  [ECustomerGender.MALE]: "Uomo",
  [ECustomerGender.FEMALE]: "Donna",
  [ECustomerGender.NOTSPECIFIED]: "Non Specificato",
}

@Pipe({
  name: 'genderDescription'
})
export class GenderDescriptionPipe implements PipeTransform {
  transform(value: ECustomerGender, ...args: unknown[]): unknown {
    return value ? (GENDERS_DESCRIPTIONS[value] || GENDERS_DESCRIPTIONS.not_specified) : '';
  }

}
