import { Pipe, PipeTransform } from '@angular/core';

import { CardTransactionType, ECardTransactionType } from '@loyx/common';

@Pipe({
  name: 'cardTransactionType',
})
export class CardTransactionTypePipe implements PipeTransform {
  transform(typeId: ECardTransactionType | string, ...args: unknown[]): unknown {
    if (!typeId) return '';

    const type = CardTransactionType.find((u) => u.id === typeId);

    return type ? type.description : typeId;
  }
}
