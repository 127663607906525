import { IMongodbDocument } from './mongodb';

import { IOrganization } from './organizations';
import { IUser } from './users';

export interface IBackofficeLog extends IMongodbDocument {
  /**   */
  resource: string;
  /**   */
  action: string;
  /**   */
  success: boolean;
  /** Eventuale organizzazione  */
  organizationId?: string;
  organization?: IOrganization;
  /** Eventuale utente  */
  userId?: string;
  user?: IUser;
  /**   */
  info?: string;
  /**   */
  error?: string;
  /**   */
  ip?: string;
}
