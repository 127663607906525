import { Pipe, PipeTransform } from '@angular/core';

import { CampaignStatus, ECampaignStatus } from '@loyx/common';

@Pipe({
  name: 'campaignStatus',
})
export class CampaignStatusPipe implements PipeTransform {
  transform(statusId: ECampaignStatus | string, ...args: unknown[]): unknown {
    if (!statusId) return '';

    const status = CampaignStatus.find((u) => u.id === statusId);

    return status ? status.description : statusId;
  }
}
