import { ConsentEntity } from './agreement.entity';
import { DbOrganizationResourceEntity } from './db.entity';
import { StoreEntity } from './store.entity';
import { UserEntity } from './user.entity';

export class AccountEntity extends DbOrganizationResourceEntity<AccountEntity> {
  firstName: string;
  lastName: string;
  email: string;
  active?: boolean;
  verified?: boolean;
  emailVerified?: boolean;
  favouriteStoreId?: string;
  // @Exclude()
  password?: string;
  otpVerify?: string;
  expireOtpVerifyDate?: string;
  otpReset?: string;
  expireOtpResetDate?: string;
  consents?: ConsentEntity[];
  reviewConsents?: boolean;
  lastLogin?: string;
  createdByUserId?: string;
  disabled?: boolean;
  disablingDate?: string;

  readonly createdByUser?: UserEntity;
  readonly favouriteStore?: StoreEntity;
}
