import { Pipe, PipeTransform } from '@angular/core';

import { AgreementTypeDescription, EAgreementTypes } from '@loyx/common';

@Pipe({
  name: 'agreementType',
})
export class AgreementTypePipe implements PipeTransform {
  transform(typeId: EAgreementTypes | string, ...args: unknown[]): unknown {
    if (!typeId) return '';

    const type = AgreementTypeDescription.find((u) => u.id === typeId);

    return type ? type.description : typeId;
  }
}
