import { IMongodbDocument } from './mongodb';

import { IOrganizationEntityDocument } from './organizations';

export interface IStore extends IMongodbDocument, IOrganizationEntityDocument {
  active: boolean;
  code: number;
  default: boolean;
  description: string;
  storeGroupId?: string;
  storeGroup?: IStoreGroup;
  address?: string;
  city?: string;
  lat?: string;
  long?: string;
  email?: string;
  website?: string;
  contact?: string;
  openingCalendar?: IOpeningWeekCalendar;
  contacts?: IStoreContact[];
  activeContract?: boolean;
}
export interface IStoreGroup extends IMongodbDocument, IOrganizationEntityDocument {
  active: boolean;
  description: string;
}

export interface IOpeningWeekCalendar {
  [weekday: number]: IOpeningDayInfo;
}

export interface IOpeningDayInfo {
  closed?: boolean;
  timetables?: {
    description?: string;
    start?: string;
    end?: string;
  }[];
}

export interface IStoreContact {
  type?: string;
  description?: string;
  value?: string;
}
