<div class="fileUploadInput mt-3" [nbSpinner]="uploading">
  <input
    type="file"
    accept="image/*"
    fullWidth
    size="small"
    id="file"
    (change)="fileInputSelected($event)"
    #fileInput
    placeholder="Carica file"
    [multiple]="multiUpload"
  />
  <button>
    <nb-icon icon="file-add-outline"></nb-icon>
  </button>
</div>

<div>
  <div class="d-flex justify-content-center mb-3 mt-3">
    <button
      nbButton
      outline
      status="success"
      (click)="uploadFile()"
      [disabled]="file.length == 0 || uploading"
    >
      <nb-icon icon="cloud-upload-outline"></nb-icon>
      Carica file
    </button>
  </div>

  <div *ngIf="uploadFailed">
    <nb-alert outline="danger"> Errore nel caricamento del file! </nb-alert>
  </div>

  <div *ngIf="uploadComplete" class="mt-4">
    <nb-alert outline="success"> File Caricato con successo </nb-alert>

    <div class="row mt-1" *ngIf="uploadedImages.length == 1">
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
        <img
          [src]="uploadedImages[0].url"
          alt="{{ uploadedImages[0].name }}"
          class="image-item-selected img-fluid"
        />
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <div class="ml-4">
          <p><strong>Nome file:</strong> {{ uploadedImages[0].name }}</p>
          <p><strong>Dimensione:</strong> {{ uploadedImages[0].size | bytes }}</p>
        </div>
      </div>
    </div>

    <div class="column mt-1" *ngIf="uploadedImages.length > 1">
      <div
        class="d-flex col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-5"
        *ngFor="let uploadedImage of uploadedImages"
      >
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <img
            [src]="uploadedImage.url"
            alt="{{ uploadedImage.name }}"
            class="image-item-selected img-fluid"
          />
        </div>
        <div class="ml-4">
          <p><strong>Nome file:</strong> {{ uploadedImage.name }}</p>
          <p><strong>Dimensione:</strong> {{ uploadedImage.size | bytes }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
