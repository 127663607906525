<div class="image-gallery-container" [class.scrollable]="isScreenSmall()">
  <div class="row image-gallery" *ngIf="images?.length">
    <div [ngClass]="images.length > 3 ? 'col-xl-2' : 'col-xl-4'"
      class="col-6 col-sm-6 col-md-3 col-lg-3 image-container" *ngFor="let image of images">
      <div class="image-item"
        [ngClass]="{'selection-mode' : selectionMode, 'multi-selection' : multiSelection, 'selected': isSelected(image)}"
        [style.backgroundImage]="'url(' + image.url + ')'" (click)="selectImage($event, image)">

        <div class="action-buttons">
          <ng-container *ngIf="!selectionMode">
            <nb-icon [hasPermission]="['view', 'org_medias']" icon="eye-outline" status="primary"
              [options]="{ animation: { type: 'zoom' } }" [routerLink]="['/backoffice/medias/vedi', image._id]"
              class="view-icon"></nb-icon>
            <nb-icon [hasPermission]="['delete', 'org_medias']" icon="trash-2-outline" status="danger"
              [options]="{ animation: { type: 'zoom' } }" (click)="deleteItem(image)" class="delete-icon"></nb-icon>
          </ng-container>

          <ng-container *ngIf="selectionMode">
            <nb-icon [icon]="
                isSelected(image) ? 
                  (multiSelection ? 'checkmark-square-2-outline'  : 'checkmark-circle-2-outline') :
                  (multiSelection ? 'square-outline' : 'radio-button-off-outline')
              " [status]="isSelected(image) ? 'primary' : 'basic'" class="select-icon"></nb-icon>
          </ng-container>
        </div>

        <div class="image-name">
          {{image.name}}
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center p-3" *ngIf="!images?.length">
    <span>Nessun media trovato</span>
  </div>

  <si-table-list-footer [pagination]="pagination" [totalItems]="totalItems" [currentItems]="images.length"
    [perpageOptions]="[12, 24, 48, 120]" (onChange)="loadMedias()">
  </si-table-list-footer>
</div>