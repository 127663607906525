import { DbOrganizationResourceEntity } from './db.entity';
import { ECampaignStatus } from '../constants';
import { CardTypeEntity } from './card-type.entity';
import { AgreementEntity } from './agreement.entity';
import { MediaEntity } from './media.entity';
import { UserEntity } from './user.entity';

export class CampaignEntity extends DbOrganizationResourceEntity<CampaignEntity> {
  /** Campagna attivata/disattivata  */
  active: boolean;
  /** Nome campagna  */
  name: string;
  /** Descrizione pubblica campagna  */
  description: string;
  /** Data inizio campagna  */
  startDate: string;
  /** Data fine campagna  */
  endDate: string;
  /** Data ultima per riscattare premi */
  rewardRequestMaxData: string;
  /** Tipi di carte per cui è attiva la campagna  */
  cardTypesIds: string[];
  /** Stato campagna  */
  status?: ECampaignStatus;
  /** Punti assegnati  */
  points?: number;
  /** Valore dei punti  */
  pointsValue?: number;
  /** Importo minimo per aggiungere punti  */
  minimumAmount?: number;
  /** Punti al raggiungimento dell'importo minimo */
  minimumAmountPoints?: number;
  /** Bonus attivo/non attivo */
  bonusActive?: boolean;
  /** Importo minimo per aggiungere punti bonus */
  bonusAmount?: number;
  /** Bonus punti al raggiungimento dell'importo minimo bonus  */
  bonusPoints?: number;
  /** Bonus di benvenuto ad inizio campagna (o alla prima operazione)  */
  welcomePoints?: number;
  /** Regolamento Campagna */
  termsAgreementId?: string;
  /** Utente che ha creato la card */
  createdByUserId?: string;

  mediaId?: string;
  /** Regole */
  // rules?: {
  //     triggers: any;
  //     conditions: any;
  //     results: any;
  // }[];
  readonly createdByUser?: UserEntity;
  readonly termsAgreement?: AgreementEntity;
  readonly cardTypes?: CardTypeEntity[];
  readonly media?: MediaEntity;
}

// export class CampaignRewardEntity extends DbResourceEntity {
//     // campaign: ICampaign;
//     type: ECampaignRewardType;
//     description: string;
//     // sku: string;
//     pointsThreshold: number;
//     amountThreshold: number;
//     couponValue: number;
// }
