import { Expose } from 'class-transformer';

import { OrganizationEntity } from './organization.entity';

export class DbResourceEntity {
  @Expose()
  readonly _id?: string;

  @Expose()
  readonly createdAt?: string;

  @Expose()
  readonly updatedAt?: string;
}

type IfEquals<X, Y, A, B> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

type WritableKeysOf<T> = {
  [P in keyof T]: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P, never>;
}[keyof T];

type WritablePart<T> = Pick<T, WritableKeysOf<T>>;

export class DbOrganizationResourceEntity<Entity> extends DbResourceEntity {
  organizationId?: string;

  readonly organization?: OrganizationEntity;

  constructor(
    organizationId: string,
    entityData?: Omit<WritablePart<Entity>, keyof DbOrganizationResourceEntity<Entity>>
  ) {
    super();

    this.organizationId = organizationId;

    if (entityData) Object.assign(this, entityData);
  }
}
