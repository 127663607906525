import { DbOrganizationResourceEntity } from './db.entity';
import { CampaignEntity } from './campaign.entity';
import { CardEntity } from './card.entity';
import { StoreEntity } from './store.entity';
import { UserEntity } from './user.entity';
import { ECardTransactionSource, ECardTransactionStatus, ECardTransactionType } from '../constants';
import { ApiTokenEntity } from './api-token.entity';
import { Expose } from 'class-transformer';
import { AccountEntity } from './account.entity';
import { RewardEntity } from './rewards.entity';
import { OrderEntity } from './order.entity';

export class PurchaseDocumentEntity {
  /** Tipo documento (Vendita, Reso) */
  type?: string;
  /** Data del documento */
  date?: string;
  /** Codice gestionale del riferimento documento */
  reference?: string;
  /** Numero gestionale del documento */
  number?: string;
  /** Note aggiuntive documento */
  note?: string;
}

export class CardTransactionEntity extends DbOrganizationResourceEntity<CardTransactionEntity> {
  /** Codice carta */
  // cardCode: string;
  /** Tipo transazione */
  @Expose()
  type?: ECardTransactionType;
  /** Sorgente da cui è stata effettuata la transazione */
  source: ECardTransactionSource;
  /** Stato della transazione */
  status: ECardTransactionStatus;
  /** Carta */
  cardId?: string;
  /** Importo Transazione - Obbligatorio se del tipo PURCHASE */
  purchaseAmount?: number;
  /** Punti transazione - Obbligatorio se del tipo POINTS */
  points?: number;
  /** Informazioni documento - eventualmente presente con il tipo PURCHASE */
  document?: PurchaseDocumentEntity;
  /** Campagna di riferimento - presente con il tipo POINTS */
  campaignId?: string;
  /** Negozio in cui è avvenuta la transazione */
  storeId?: string;
  /** Codice cassa del negozio (Point of sale) */
  posCode?: string;
  /** Note aggiuntive transazione */
  note?: string;
  /** Utente backoffice che ha effettuato la transazione */
  userId?: string;
  /** Account app che ha effettuato la transazione */
  accountId?: string;
  /** Token api integrazione che ha effettuato la transazione */
  apiTokenId?: string;
  /** Codice eventuale Errore */
  error?: string;
  /** Descrizione eventuale Errore */
  errorDescription?: string;
  /** Eventuale premio riscattato */
  rewardId?: string;
  /**  */
  orderId?: string;

  readonly card?: CardEntity;
  readonly campaign?: CampaignEntity;
  readonly store?: StoreEntity;
  readonly user?: UserEntity;
  readonly account?: AccountEntity;
  readonly apiToken?: ApiTokenEntity;
  readonly reward?: RewardEntity;
  readonly order?: OrderEntity;
}
