import { IMongodbDocument } from './mongodb';

import { IAccount } from './accounts';
import { IOrganization } from './organizations';

export interface IAppLog extends IMongodbDocument {
  action: string;
  success: boolean;
  organizationId?: string;
  organization?: IOrganization;
  accountId?: string;
  account?: IAccount;
  info?: string;
  error?: string;
  ip?: string;
}
