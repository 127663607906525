import { CampaignEntity } from './campaign.entity';
import { DbOrganizationResourceEntity } from './db.entity';
import { MediaEntity } from './media.entity';
import { StoreEntity } from './store.entity';
import { UserEntity } from './user.entity';

export class RewardEntity extends DbOrganizationResourceEntity<RewardEntity> {
  name: string;
  type: string;
  digitalRewardType: string;
  reviewOrderActive?: boolean;
  reference?: string;
  description?: string;
  value: number;
  quantity?: number;
  points: number;
  active: boolean;
  campaignId: string;
  storeId?: string;
  mediaId?: string;
  createdByUserId?: string;
  giftCardOptions?: GiftCardOptionsEntity;

  /** Numero degli ordini effettuati per questo premio */
  ordersCount?: number;

  readonly createdByUser?: UserEntity;
  readonly campaign?: CampaignEntity;
  readonly store?: StoreEntity;
  readonly media?: MediaEntity;
}

export class GiftCardOptionsEntity {
  minimumPurchaseAmount?: number;
  storeOption?: string;
}
