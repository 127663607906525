import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { Observable, catchError, of, throwError } from 'rxjs';
import { AccountService } from './utils/account.service';
import { NbToastrService } from '@nebular/theme';
import { SkipErrorMessageHandling } from './utils/backend.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private jwtToken: string;

  constructor(
    private authService: NbAuthService,
    private router: Router,
    private account: AccountService,
    private toastr: NbToastrService
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthToken) => {
      this.jwtToken = token.isValid() ? token.getValue() : null;
      // this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.jwtToken) {
      return next.handle(req);
    }

    let headers: HttpHeaders = req.headers;

    const skipErrorMessage = headers.has(SkipErrorMessageHandling);
    if (skipErrorMessage) {
      headers = headers.delete(SkipErrorMessageHandling);
    }

    headers = headers.set('Authorization', 'Bearer ' + this.jwtToken);

    return next
      .handle(req.clone({ headers }))
      .pipe(catchError((x) => this.handleAuthError(x, skipErrorMessage)));
  }

  private handleAuthError(err: HttpErrorResponse, skipMessageHandling: boolean = false): Observable<any> {
    if (err.status == 401) {
      this.account.logout();
    }

    if (skipMessageHandling) {
      return throwError(() => err);
    }

    switch (err.status) {
      case 401:
        this.toastr.danger(err.error.message, 'Ooops');
        break;
      case 403:
        const message = err.error?.message.includes('Forbidden')
          ? 'Non hai i permessi per eseguire questa azione'
          : err.error.message;

        this.toastr.warning(message, 'Ooops');
        break;
      default:
        this.toastr.danger(err.error.message, 'Ooops');
        break;
    }

    return of(err.message);
  }
}
