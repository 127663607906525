export enum EBarcodeType {
  EAN13 = 'EAN13',
  CODE128 = 'CODE128',
}

export interface IBarcodeTypeInfo {
  id: EBarcodeType;
  description: string;
}

export const BarcodeTypeInfo: IBarcodeTypeInfo[] = [
  {
    id: EBarcodeType.EAN13,
    description: 'EAN 13',
  },
];

export const RegexMatchesMessage =
  'La password deve essere di almeno 8 caratteri e contenere una lettera maiuscola e un numero.';
