<nb-form-field *ngIf="!accountIdFormControl">
  <input
    #accountFilterInput
    nbInput
    type="text"
    fieldSize="small"
    fullWidth
    placeholder="Cerca account"
    (input)="onChange($event)"
    [nbAutocomplete]="acAccounts"
  />

  <button type="button" nbSuffix nbButton ghost (click)="clearInput($event)">
    <nb-icon [icon]="'close-outline'" pack="eva"></nb-icon>
  </button>

  <nb-autocomplete #acAccounts (selectedChange)="onSelectionChange($event)">
    <nb-option *ngFor="let i of filteredAccounts" [value]="i._id"
      >{{ i.firstName }} {{ i.lastName }} - {{ i.email }}</nb-option
    >
  </nb-autocomplete>
</nb-form-field>
