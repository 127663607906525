<button
  nbButton
  outline
  size="medium"
  class="btn"
  type="button"
  (click)="openDialog()"
  *ngIf="!mediaFromParent"
>
  <nb-icon nbPrefix icon="camera-outline"></nb-icon>Seleziona immagine
</button>

<div *ngIf="mediaFromParent" class="d-flex row">
  <div class="mx-3 mt-2 image-item-selected" [style.backgroundImage]="'url(' + mediaFromParent?.url + ')'">
    <nb-icon
      [hasPermission]="['update', 'org_medias']"
      icon="close-outline"
      status="danger"
      [options]="{ animation: { type: 'zoom' } }"
      (click)="reset()"
      class="icon close-icon mt-1"
    ></nb-icon>
    <nb-icon
      [hasPermission]="['update', 'org_medias']"
      icon="swap-outline"
      status="primary"
      [options]="{ animation: { type: 'zoom' } }"
      (click)="openDialog()"
      class="icon swap-icon"
    ></nb-icon>
  </div>
</div>
