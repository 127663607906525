import { IMongodbDocument } from './mongodb';

import { EGiftCardStatus } from '../constants';
import { ICard } from './cards';
import { IOrder } from './orders';
import { IOrganizationEntityDocument } from './organizations';
import { IUser } from './users';
import { IStore, IStoreGroup } from './stores';

export interface IGiftCard extends IMongodbDocument, IOrganizationEntityDocument {
  code: string;
  publicCode?: string;
  active: boolean;
  value: number;
  balance: number;
  date: string;
  note: string;
  validFrom: string;
  validTo: string;
  status: EGiftCardStatus;
  locked: boolean;
  orderId?: string;
  order?: IOrder;
  createdByUserId?: string;
  createdByUser?: IUser;
  cardId?: string;
  card?: ICard;
  conditions?: {
    minimumPurchaseAmount?: number;
    stores?: IStore[];
    storesIds?: string[];
    storesGroups?: IStoreGroup[];
    storesGroupsIds?: string[];
  };
}
