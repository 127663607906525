import { Injectable } from '@angular/core';

import { IStore, IStoreGroup } from '@loyx/common';

import { ApiBackendBaseService } from '../../@core/utils/api-backend-base.service';

@Injectable({
  providedIn: 'root',
})
export class StoresService extends ApiBackendBaseService<IStore> {
  override basePath = ':organization/stores';
}

@Injectable({
  providedIn: 'root',
})
export class StoresGroupsService extends ApiBackendBaseService<IStoreGroup> {
  override basePath = ':organization/store-groups';
}
