import { AgreementEntity } from './agreement.entity';
import { DbOrganizationResourceEntity } from './db.entity';

export class CardTypeEntity extends DbOrganizationResourceEntity<CardTypeEntity> {
  /** Codice univoco tipo carta */
  code: string;
  /** Descrizione tipo carta */
  description: string;
  /** Tipo carta predefinito per l'organizzazione */
  default?: boolean;
  /** Regolamento */
  termsAgreementId?: string;

  readonly termsAgreement?: AgreementEntity;
}
