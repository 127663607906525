<nb-badge class="badge-env" [ngClass]="'env-' + envType" [text]="envType"></nb-badge>

<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>

    <a class="logo" href="#" (click)="navigateHome()">
      <img src="assets/images/loyx-platform-logo.svg" height="45" alt="Loyx Platform - Backoffice">
    </a>

    <div></div>
  </div>

  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">
    <!--     <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action">
      <nb-user
        [nbContextMenu]="userMenu"
        nbContextMenuTag="header-user-menu"
        nbContextMenuTrigger="hover"
        [onlyPicture]="userPictureOnly"
        [name]="user?.username"
        shape="rectangle"
        title="{{ user?.role | userRole }}"
      >
        <!-- [picture]="user?.picture" -->
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
