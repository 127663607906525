import { IMongodbDocument } from './mongodb';

import { EUserRole, EUserType } from '../constants';
import { IOrganization } from './organizations';
import { IStore, IStoreGroup } from './stores';

export interface IUser extends IMongodbDocument {
  active: boolean;
  type: EUserType | string;
  role: EUserRole | string;
  firstName: string;
  lastName?: string;
  username: string;
  email: string;
  password?: string;
  /** Eventuale organizzazione  */
  organizationId?: string;
  organization?: IOrganization;
  /** Eventuale gruppo negozi  */
  storeGroupId?: string;
  storeGroup?: IStoreGroup;
  /** Eventuale negozio  */
  storeId?: string;
  store?: IStore;
  otpPasswordReset?: string;
  otpPasswordResetExpire?: string;
  allowedStores?: string[];
}

export interface UserJwtTokenPayload {
  // _id: string;
  userid: string;
  type: string;
  role: string;
  username: string;
  email: string;
  organizationId?: string;
  organization?: IOrganization;
}
