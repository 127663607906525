import { IMongodbDocument } from './mongodb';

import { EOrderStatus, EOrderSource, EOrderDeliveryStatus } from '../constants';
import { IAccount } from './accounts';
import { ICampaign } from './campaigns';
import { ICard } from './cards';
import { IGiftCard } from './giftcards';
import { IOrganizationEntityDocument } from './organizations';
import { IReward } from './rewards';
import { IStore } from './stores';
import { IUser } from './users';

export interface IOrder extends IMongodbDocument, IOrganizationEntityDocument {
  date?: string;
  status?: EOrderStatus;
  deliveryStatus?: EOrderDeliveryStatus;
  campaignId: string;
  campaign?: ICampaign;
  cardId: string;
  card?: ICard;
  userId?: string;
  user?: IUser;
  accountId?: string;
  account?: IAccount;
  rewardId: string;
  reward?: IReward;
  giftCardId?: string;
  giftCard?: IGiftCard;
  source: EOrderSource;
  points?: number;
  storeId?: string;
  store?: IStore;
  summary?: string;
  note?: string;
  refunded?: boolean;
}
