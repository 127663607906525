import { Injectable } from '@angular/core';

import { ConsentEntity, IAccount } from '@loyx/common';
import { ApiBackendBaseService } from '../../@core/utils/api-backend-base.service';

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends ApiBackendBaseService<IAccount> {
  override basePath = ':organization/accounts';

  async refuseConsent(idAccount: string, consent: ConsentEntity) {
    const endpoint = this.getPath() + `/${idAccount}/refuse-consent`;

    this.backend.post(endpoint, consent).subscribe({
      next: (res) => {},
      error: (errorRes) => {
        const error = errorRes ? errorRes.error : null;
      },
    });
  }
}
