import { DbResourceEntity } from "./db.entity";
import { OrganizationEntity } from "./organization.entity";
import { UserEntity } from "./user.entity";

export class BackofficeLogEntity extends DbResourceEntity {
  resource: string;
  action: string;
  success: boolean;
  organizationId?: string;
  userId?: string;
  info?: string;
  error?: string;
  ip?: string;

  readonly organization?: OrganizationEntity;
  readonly user?: UserEntity;

  constructor(data?: Omit<BackofficeLogEntity, keyof DbResourceEntity>) {
    super();

    if (data) Object.assign(this, data);
  }
}