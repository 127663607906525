import { IMongodbDocument } from './mongodb';
import { IOrganizationEntityDocument } from './organizations';
import { IUser } from './users';

export interface IMedia extends IMongodbDocument, IOrganizationEntityDocument {
  bucketName?: string;
  filePath?: string;
  contentType?: string;
  name?: string;
  createdByUserId?: string;
  createdByUser: IUser;
  description?: string;
  size?: number;
  url?: string;
  media?: string;
}
