export enum EAppUserAction {
  CREATE_CARD = 'create_card',
  REGISTER_CARD = 'register_card',
  SET_FAVOURITE_STORE = 'set_favourite_store',
  CREATE_ORDER = 'create_order',
  CHANGE_PASSWORD = 'change_password',
  SET_CONSENTS = 'set_consents',
  REQUEST_FORGOT_PASSWORD = 'request_forgot_password',
  LOGIN = 'login',
}

export const ActionDescription = [
  {
    id: EAppUserAction.CREATE_CARD,
    description: 'Creazione carta',
  },
  {
    id: EAppUserAction.REGISTER_CARD,
    description: 'Associazione carta',
  },
  {
    id: EAppUserAction.SET_FAVOURITE_STORE,
    description: 'Assegnazione negozio preferito',
  },
  {
    id: EAppUserAction.CREATE_ORDER,
    description: 'Creazione ordine',
  },
  {
    id: EAppUserAction.CHANGE_PASSWORD,
    description: 'Cambio password',
  },
  {
    id: EAppUserAction.SET_CONSENTS,
    description: 'Modifica consensi',
  },
  {
    id: EAppUserAction.REQUEST_FORGOT_PASSWORD,
    description: 'Richiesta password dimenticata',
  },
  {
    id: EAppUserAction.LOGIN,
    description: 'Accesso',
  },
];
