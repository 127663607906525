import { DbOrganizationResourceEntity } from './db.entity';
import { ECardStatus, ECustomerGender } from '../constants';
import { CardTypeEntity } from './card-type.entity';
import { StoreEntity } from './store.entity';
import { AccountEntity } from './account.entity';
import { ConsentEntity } from './agreement.entity';
import { UserEntity } from './user.entity';
import { ApiTokenEntity } from './api-token.entity';

export class CardEntity extends DbOrganizationResourceEntity<CardEntity> {
  /** Tipo carta */
  cardTypeId: string;
  /** Carta attiva/non attiva */
  active?: boolean;
  /** Codice carta (univoco per organizzazione) */
  code?: number;
  /** Barcode fisico carta facoltativo (univoco per organizzazione) */
  barcode?: string;
  /** Data di attivazione della carta */
  activationDate?: string;
  /** Negozio predefinito carta */
  storeId?: string;
  /**
   * CLIENTE o AZIENDA
   */
  /** Cliente business */
  businessCustomer?: boolean;
  /** Nome cliente */
  firstName?: string;
  /** Cognome cliente */
  lastName?: string;
  /** */
  gender?: ECustomerGender | string;
  /** */
  birthDate?: string;
  /** Ragione Sociale */
  companyName?: string;
  /** Partita iva */
  companyVatNumber?: string;
  /** Nota private */
  privateNote?: string;
  /**
   * CONTATTI
   */
  /** Email */
  email?: string;
  /** Telefono */
  phone?: string;
  /** Indirizzo */
  address?: AddressEntity;
  /**
   * ALTRO
   */
  /** Account associato alla card */
  accountId?: string;
  /** Data associazione ad account */
  accountAssociationDate?: string;
  /** Stato carta  */
  status?: ECardStatus;
  /**
   * STATISTICHE
   */
  /** Numero degli acquisti effettuati dalla carta */
  purchasesCount?: number;
  /** Importo totale degli acquisti effettuati dalla carta */
  purchasesAmount?: number;
  /** Importo medio sul totale acquisti dalla carta */
  purchasesAverageAmount?: number;
  /** Data del primo acquisto effettuato dalla carta */
  firstPurchaseDate?: string;
  /** Data dell'ultimo acquisto effettuato dalla carta */
  lastPurchaseDate?: string;
  /**
   * CONSENSI
   */
  consents?: ConsentEntity[] | null;
  /** Utente che ha creato la card */
  createdByUserId?: string;

  /** Carta disabilitata/non disabilitata */
  disabled?: boolean;
  /** Data disabilitazione */
  disablingDate?: string;

  createdByApiTokenId?: string;

  readonly createdByUser?: UserEntity;
  readonly createdByApiToken?: ApiTokenEntity;
  readonly account?: AccountEntity;
  readonly store?: StoreEntity;
  readonly cardType?: CardTypeEntity; // PopulatedDoc<ICardType>;// MongoIdOrDocument<ICardType>; PopulatedDoc
}

export class AddressEntity {
  // street?: string;
  address1?: string;
  address2?: string;
  province?: string;
  city?: string;
  postal?: string;
  country?: string;
}
