import { IMongodbDocument } from './mongodb';

import { ENewsStatus } from '../constants';
import { IOrganizationEntityDocument } from './organizations';
import { IMedia } from './medias';
import { IUser } from './users';

export interface INews extends IMongodbDocument, IOrganizationEntityDocument {
  /** Titolo della news */
  title: string;
  /** Secondo titolo della news */
  subTitle?: string;
  /** News Attiva/Non Attiva */
  active: boolean;
  /** Corpo della news */
  body: string;
  /** Stato della news */
  status?: ENewsStatus;
  /** News attiva dal */
  activeFrom?: string;
  /** News attiva fino al */
  activeTo?: string;
  /** Data della news */
  date: string;
  /** Media della news */
  mediaId?: string;
  /** Utente che ha creato la card */
  createdByUserId?: string;

  createdByUser?: IUser;
  media?: IMedia;
}
