export enum EOrderStatus {
  /** IN ATTESA DI ESSERE CONFERMATO */
  PENDING = 'PENDING',
  /** CONFERMATO */
  PROCESSED = 'PROCESSED',
  /** ANNULLATO */
  CANCELED = 'CANCELED',
}

export enum EOrderDeliveryStatus {
  /**  */
  UNKNOW = '',
  /** IN ATTESA */
  PENDING = 'PENDING',
  /** ORDINATO */
  ORDERED = 'ORDERED',
  /** IN ARRIVO */
  // INCOMING = 'INCOMING',
  /** DISPONIBILE PER IL RITIRO */
  AVAILABLE = 'AVAILABLE',
  /** CONSEGNATO / RITIRATO */
  DELIVERED = 'DELIVERED',
}

export enum EOrderSource {
  /** Transazione inserita manualmente su backoffice */
  BACKOFFICE = 'BACKOFFICE',
  /** Transazione inserita con utilizzo delle integrazioni api */
  API = 'API',
  /** Transazione inserita tramite app */
  APP = 'APP',
}

export const OrderDeliveryStatuses = [
  {
    id: EOrderDeliveryStatus.PENDING,
    description: 'In attesa',
  },
  {
    id: EOrderDeliveryStatus.ORDERED,
    description: 'Prenotato',
  },
  // {
  //   id: EOrderDeliveryStatus.INCOMING,
  //   description: 'In arrivo',
  // },
  {
    id: EOrderDeliveryStatus.AVAILABLE,
    description: 'Disponibile al ritiro',
    sendEmail: true,
  },
  {
    id: EOrderDeliveryStatus.DELIVERED,
    description: 'Consegnato',
  },
];

export const OrderStatuses = [
  {
    id: EOrderStatus.PENDING,
    description: 'In attesa',
    deliveryEnabled: false,
  },

  {
    id: EOrderStatus.PROCESSED,
    description: 'Confermato',
    deliveryEnabled: true,
  },
  {
    id: EOrderStatus.CANCELED,
    description: 'Annullato',
    refundEnabled: true,
  },
];
