export enum EFlyerStatus {
  /** SCONOSCIUTO */
  UNKNOWN = '',
  /** NON ATTIVO */
  INACTIVE = 'INACTIVE',
  /** IN ARRIVO  */
  INCOMING = 'INCOMING',
  /** IN CORSO */
  RUNNING = 'RUNNING',
  /** SCADUTA */
  EXPIRED = 'EXPIRED',
}

export const FlyerStatus = [
  {
    id: EFlyerStatus.UNKNOWN,
    description: 'Sconosciuto',
  },
  {
    id: EFlyerStatus.INACTIVE,
    description: 'Non attivo',
  },
  {
    id: EFlyerStatus.INCOMING,
    description: 'In arrivo',
  },
  {
    id: EFlyerStatus.RUNNING,
    description: 'In corso',
  },
  {
    id: EFlyerStatus.EXPIRED,
    description: 'Scaduto',
  },
];
