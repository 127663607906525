import { IMongodbDocument } from './mongodb';

import { EAgreementTypes, EConsentSource } from '../constants';
import { IOrganizationEntityDocument } from './organizations';

export interface IAgreement extends IMongodbDocument, IOrganizationEntityDocument {
  active: boolean;
  type: EAgreementTypes;
  title: string;
  description?: string;
  mandatory: boolean;
  retentionPeriod?: number;
  url?: string;
  displayOrder?: number;
  content?: string;
}

export interface IConsent {
  agreementId: string;
  agreementTitle?: string;
  agreementType: EAgreementTypes;
  accepted?: boolean;
  date?: string;
  source?: EConsentSource;
  validUntil?: string;
}
