export enum EGiftCardStatus {
  /** IN USO */
  LOCKED = 'LOCKED',
  /** VALIDA */
  VALID = 'VALID',
  /** UTILIZZATA  */
  REDEEMED = 'REDEEMED',
  /** SCADUTA */
  EXPIRED = 'EXPIRED',
  /** IN ATTESA */
  PENDING = 'PENDING',
  /** NON ATTIVA */
  INACTIVE = 'INACTIVE',
}

export enum EGiftCardType {
  LOYXGIFTCARD = 'loyxGiftCard',
}

export enum EGiftCardTransactionSource {
  /** Transazione inserita manualmente su backoffice */
  BACKOFFICE = 'BACKOFFICE',
  /** Transazione inserita con utilizzo delle integrazioni api */
  API = 'API',
}

export enum EGiftCardTransactionType {
  /** Transazione di utilizzo */
  PURCHASE = 'PURCHASE',
  /** Rimozione  */
  CREDIT_REMOVAL = 'CREDIT_REMOVAL',
  /** Transazione di creazione */
  CHARGE = 'CHARGE',
}

export enum EGiftCardTransactionStatus {
  /** IN CODA */
  // QUEUE = 'QUEUE',
  /** IN ATTESA */
  // PENDING = 'PENDING',
  /** IN CORSO */
  PROCESSING = 'PROCESSING',
  /** PROCESSATA */
  PROCESSED = 'PROCESSED',
  /** ANNULLATA */
  CANCELED = 'CANCELED',
}

export const GiftCardTransactionStatusDescriptions = [
  {
    id: EGiftCardTransactionStatus.PROCESSING,
    description: 'IN CORSO',
  },
  {
    id: EGiftCardTransactionStatus.PROCESSED,
    description: 'PROCESSATA',
  },
  {
    id: EGiftCardTransactionStatus.CANCELED,
    description: 'ANNULLATA',
  },
];

export const GiftCardTransactionTypeDescriptions = [
  {
    id: EGiftCardTransactionType.CHARGE,
    description: 'Ricarica',
  },
  {
    id: EGiftCardTransactionType.CREDIT_REMOVAL,
    description: 'Rimozione credito',
  },
  {
    id: EGiftCardTransactionType.PURCHASE,
    description: 'Utilizzo',
  },
];

export const GiftCardStatusDescriptions = [
  {
    id: EGiftCardStatus.PENDING,
    description: 'in attesa',
  },
  {
    id: EGiftCardStatus.LOCKED,
    description: 'bloccata',
  },
  {
    id: EGiftCardStatus.VALID,
    description: 'valida',
  },
  {
    id: EGiftCardStatus.REDEEMED,
    description: 'utilizzata',
  },
  {
    id: EGiftCardStatus.EXPIRED,
    description: 'scaduta',
  },
  {
    id: EGiftCardStatus.INACTIVE,
    description: 'non attiva',
  },
];
