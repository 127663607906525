export enum EAgreementTypes {
  PRIVACY_POLICY = 'PRIVACY_POLICY',

  PRIVACY_CONSENT = 'PRIVACY_CONSENT',

  TERMS = 'TERMS',
}

export const AgreementTypeDescription = [
  {
    id: EAgreementTypes.PRIVACY_CONSENT,
    description: 'Consenso trattamento dati',
  },
  {
    id: EAgreementTypes.PRIVACY_POLICY,
    description: 'Informativa Privacy',
  },
  {
    id: EAgreementTypes.TERMS,
    description: 'Regolamento',
  },
];

export enum EConsentSource {
  APP = 'APP',
  STORE = 'STORE',
  BACKOFFICE = 'BACKOFFICE',
  ACCOUNT = 'ACCOUNT',
  INTEGRATION = 'INTEGRATION',
}
