export enum ECustomerGender {
  NOTSPECIFIED = 'not_specified',
  FEMALE = 'female',
  MALE = 'male',
  NON_BINARY = 'non_binary',
}

export const CustomerGenders: { value: ECustomerGender; description: string }[] = [
  { value: ECustomerGender.NOTSPECIFIED, description: 'Non specificato' },
  { value: ECustomerGender.FEMALE, description: 'Donna' },
  { value: ECustomerGender.MALE, description: 'Uomo' },
  { value: ECustomerGender.NON_BINARY, description: 'Non binario' },
];
