import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IBackendQueryPagination } from '../../../@core/utils/backend.service';

@Component({
  selector: 'si-table-list-footer',
  styleUrls: ['./table-list-footer.component.scss'],
  template: `
    <div class="row">
      <div class="col-12 col-md-7 col-lg-8">
        <pagination
          [align]="true"
          [itemsPerPage]="pagination.perpage"
          [totalItems]="totalItems"
          [maxSize]="5"
          [boundaryLinks]="true"
          [rotate]="true"
          [(ngModel)]="pagination.page"
          (pageChanged)="pageChanged($event)"
          previousText="&lsaquo;"
          nextText="&rsaquo;"
          firstText="&laquo;"
          lastText="&raquo;"
        ></pagination>
      </div>

      <div class="col-6 col-md-3 col-lg-2 text-right">
        <h6 class="text-right" *ngIf="currentItems" style="padding-right: 10px;line-height: 2.4rem;">
          {{ firstItemListed }}-{{ lastItemListed }} di {{ totalItems }}
        </h6>
      </div>

      <div class="col-6 col-md-2 col-lg-2 text-right">
        <select
          class="form-control"
          id="perpage"
          [(ngModel)]="pagination.perpage"
          (change)="perPageChanged()"
        >
          <option *ngFor="let item of perpageOptions">{{ item }}</option>
        </select>
      </div>
    </div>
  `,
})
export class TableListFooterComponent implements OnChanges {
  @Output() onChange = new EventEmitter<IBackendQueryPagination>();
  @Output() selectPie = new EventEmitter<{ value: number; name: string; color: string }>();

  @Input('totalItems') totalItems: number;
  @Input('currentItems') currentItems: number = 0;
  @Input('pagination') pagination: IBackendQueryPagination;
  @Input('perpageOptions') perpageOptions: number[] = [10, 25, 50, 100];

  firstItemListed: number = 0;
  lastItemListed: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    this.firstItemListed = this.pagination.perpage * (this.pagination.page - 1) + 1;
    this.lastItemListed =
      this.pagination.perpage * this.pagination.page - (this.pagination.perpage - this.currentItems);
  }

  pageChanged(event: any): void {
    if (this.pagination.page !== event.page) {
      this.pagination.page = event.page;
      this.onChange.emit(this.pagination);
    }
  }

  perPageChanged() {
    this.pagination.page = 1;
    this.onChange.emit(this.pagination);
  }
}
