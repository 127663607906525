import { EFlyerStatus } from '../constants';
import { DbOrganizationResourceEntity } from './db.entity';
import { MediaEntity } from './media.entity';
import { StoreGroupEntity } from './store-group.entity';
import { StoreEntity } from './store.entity';
import { UserEntity } from './user.entity';

export class FlyerEntity extends DbOrganizationResourceEntity<FlyerEntity> {
  active: boolean;
  title: string;
  description?: string;
  activeFrom?: string;
  activeTo?: string;
  validFrom: string;
  validTo: string;
  bannerMediaId: string;
  mediasIds?: string[];
  storesGroupsIds?: string[];
  storesIds?: string[];
  status?: EFlyerStatus;
  createdByUserId?: string;

  readonly createdByUser?: UserEntity;
  readonly stores?: StoreEntity[];
  readonly storesGroups?: StoreGroupEntity[];
  readonly medias?: MediaEntity[];
  readonly bannerMedia?: MediaEntity;
}
