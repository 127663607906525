import { IMongodbDocument } from './mongodb';

import { ICampaign } from './campaigns';
import { IOrganizationEntityDocument } from './organizations';
import { IMedia } from './medias';
import { IUser } from './users';

export interface IReward extends IMongodbDocument, IOrganizationEntityDocument {
  active: boolean;
  name: string;
  type: string;
  digitalRewardType: string;
  reviewOrderActive?: boolean;
  reference?: string;
  description: string;
  value: number;
  quantity: number;
  points: number;
  campaignId: string;
  campaign?: ICampaign;
  mediaId?: string;
  media?: IMedia;
  createdByUserId?: string;
  createdByUser: IUser;
  giftCardOptions?: {
    minimumPurchaseAmount?: number;
    storeOption?: string;
  };
}
