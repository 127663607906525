import { Pipe, PipeTransform } from '@angular/core';

import {
  EBackofficeResource,
  EBackofficeUserPermission,
  EUserRole,
  PermissionDescription,
  ResourceDescription,
  USER_ROLES,
} from '@loyx/common';

@Pipe({
  name: 'userRole',
})
export class UserRolePipe implements PipeTransform {
  transform(roleId: EUserRole | string, ...args: unknown[]): unknown {
    if (!roleId) return '';

    const role = USER_ROLES.find((u) => u.id === roleId);

    return role ? role.description : roleId;
  }
}

@Pipe({
  name: 'userResource',
})
export class UserResourcePipe implements PipeTransform {
  transform(resourceId: EBackofficeResource | string, ...args: unknown[]): unknown {
    if (!resourceId) return '';

    const resource = ResourceDescription.find((u) => u.id === resourceId);

    return resource ? resource.description : resource.id;
  }
}

@Pipe({
  name: 'userPermission',
})
export class UserPermissionPipe implements PipeTransform {
  transform(permissionId: EBackofficeUserPermission | string, ...args: unknown[]): unknown {
    if (!permissionId) return '';

    const permission = PermissionDescription.find((u) => u.id === permissionId);

    return permission ? permission.description : permission.id;
  }
}
