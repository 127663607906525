import { Exclude } from 'class-transformer';

import { EGiftCardStatus } from '../constants';
import { DbOrganizationResourceEntity } from './db.entity';
import { OrderEntity } from './order.entity';
import { UserEntity } from './user.entity';
import { CardEntity } from './card.entity';
import { StoreEntity } from './store.entity';
import { StoreGroupEntity } from './store-group.entity';

export class GiftCardEntity extends DbOrganizationResourceEntity<GiftCardEntity> {
  @Exclude()
  code?: string;

  active: boolean;
  value: number;
  balance?: number;
  validFrom?: string;
  validTo: string;
  locked?: boolean;
  orderId?: string;
  createdByUserId?: string;
  cardId?: string;
  conditions?: GiftCardConditionsEntity;

  readonly card?: CardEntity;
  readonly createdByUser?: UserEntity;
  readonly publicCode?: string;
  readonly status?: EGiftCardStatus;
  readonly order?: OrderEntity;
}

export class GiftCardConditionsEntity {
  minimumPurchaseAmount?: number;
  storesIds?: string[];
  storesGroupsIds?: string[];

  readonly storesGroups?: StoreGroupEntity[];
  readonly stores?: StoreEntity[];
}
