import { IMongodbDocument } from './mongodb';

import { EBarcodeType } from '../constants';
import { IMedia } from './medias';

export interface IOrganizationEntityDocument {
  /** Organizzazione/Azienda  */
  organizationId: string;
  organization?: IOrganization;
}

export interface IOrganization extends IMongodbDocument {
  description: string;
  settings?: IOrganizationSettings;

  mainLogo?: IMedia;
}

export interface IOrganizationSettings {
  cards?: IOrganizationSettingsCards;
  general?: IOrganizationGeneralSettings;
  administration?: IOrganizationAdministrationSettings;
  graphics?: IOrganizationGraphicsSettings;
}

export interface IOrganizationAdministrationSettings {
  /** Abilitazione commerciale */
  storesContractManagementEnabled?: boolean;
}

export interface IOrganizationGeneralSettings {
  /** Notifiche email */
  notifications?: boolean;
}

export interface IOrganizationGraphicsSettings {
  logoId?: string;

  logo?: IMedia;
}

export interface IOrganizationSettingsCards {
  /** Circolarità attiva sull'organizzazione. Se non attiva le tessere sono valide sono nel negozio o gruppo negozi di appartenza */
  circularity?: boolean;
  /** Generazione barcode automatica */
  barcodeGeneration?: boolean;
  /** Tipologia del barcode */
  barcodeType?: EBarcodeType;
  /** Prefisso barcode */
  barcodePrefix?: number;

  /** Tipo di generazione del codice */
  // codeGeneration?: ECardCodeGenerationType;
  /** Tipologia del codice carte */
  // codeType?: ECardCodeType;
  // codeGenerationRules: {}
}

// export enum ECardCodeType {
//   QRCODE = 'QRCODE',
//   BARCODE_CODE128 = 'CODE128',
//   BARCODE_EAN13 = 'EAN13',
// }

// export interface ICardCodeTypeInfo {
//   id: ECardCodeType;
//   description: string;
// }

// export const CardCodeTypeInfo: ICardCodeTypeInfo[] = [{
//   id: ECardCodeType.QRCODE, description: "QrCode"
// }, {
//   id: ECardCodeType.BARCODE_CODE128, description: "Barcode Code128"
// }, {
//   id: ECardCodeType.BARCODE_EAN13, description: "Barcode Ean13"
// }];

// export enum ECardCodeGenerationType {
//   AUTOMATIC = 'AUTOMATIC',
//   MANUAL = 'MANUAL',
//   // CUSTOM = 'CUSTOM',
// }

// export interface ICardCodeGenerationTypeInfo {
//   id: ECardCodeGenerationType;
//   description: string;
// }

// export const CardCodeGenerationTypeInfo: ICardCodeGenerationTypeInfo[] = [{
//   id: ECardCodeGenerationType.AUTOMATIC, description: "Automatica"
// }, {
//   id: ECardCodeGenerationType.MANUAL, description: "Manuale"
// }];
