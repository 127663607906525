export enum EEMailTemplate {
  CARD_ACTIVATION = 'card-activation-complete',
  ACCOUNT_PASSWORD_CHANGE = 'account-password-change',
  ACCOUNT_CONFIRM_REGISTRATION = 'account-confirm-registration',
  ACCOUNT_OTP_VERIFICATION = 'account-otp-verification',
  ACCOUNT_PASSWORD_RECOVERY = 'account-password-recovery',
  GIFTCARD_REQUEST_COMPLETE = 'giftcard-request-complete',
  ORDER_STATUS_CHANGE = 'order-status-change',
  ORDER_REQUEST = 'order-request',
  ORDER_DELIVERY_STATUS_CHANGE = 'order-delivery-status-change',
  ORDER_CANCELED = 'order-canceled',
  AUTH_USER_RECOVERY_PASSWORD = 'auth-user-recovery-password',
  AUTH_USER_CONFIRM_CREATION = 'auth-user-confirm-creation',
  AUTH_USER_PASSWORD_CHANGE = 'auth-user-password-change',
  ORGANIZATION_USER_ORDER_CREATION = 'organization-user-order-creation',
  ORGANIZATION_USER_ADD_POINTS_CARD = 'organization-user-add-points-card',
  ORGANIZATION_USER_REMOVE_POINTS_CARD = 'organization-user-remove-points-card',
  ORGANIZATION_USER_REWARD_STOCK_QUANTITY = 'organization-user-reward-stock-quantity',
  ORGANIZATION_USER_GIFTCARD_CREATED = 'organization-user-giftcard-created',
  ORGANIZATION_USER_FIDELITYCARD_CREATED = 'organization-user-fidelitycard-created',
  ORGANIZATION_ACCOUNT_DELETION = 'organization-account-deletion',
  ORGANIZATION_ORDER_CANCELED = 'organization-order-canceled',
}
