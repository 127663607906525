import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { lastValueFrom } from 'rxjs';

@Directive({
  selector: '[hasPermission]',
})
export class hasPermissionDirective implements OnInit {
  @Input('hasPermission') requestedPermission: string[];
  @Input('hideIfHasPermission') hideIfHasPermission: boolean = false;

  constructor(private elementRef: ElementRef, private accessChecker: NbAccessChecker) {}

  ngOnInit(): void {
    this.hasPermissionCheck();
  }

  async hasPermissionCheck() {
    const permission = this.requestedPermission[0];
    const resource = this.requestedPermission[1];

    if (!permission || !resource) return;

    const hasPermission = await lastValueFrom(this.accessChecker.isGranted(permission, resource));

    if (this.hideIfHasPermission) this.elementRef.nativeElement.style.display = hasPermission ? 'none' : '';
    else this.elementRef.nativeElement.style.display = hasPermission ? '' : 'none';
  }
}
