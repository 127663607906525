import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() { }

  async handleError(error: any): Promise<void> {
    const chunkFailedMessage = /^(?=.*Loading chunk)(?=.*failed)/;

    if (chunkFailedMessage.test(error.message)) {
      const reload = confirm("É necessario ricaricare la pagina per scaricare gli ultimi aggiornamenti. Ricaricare ora?")

      if (reload)
        window.location.reload();
    }
  }
}
