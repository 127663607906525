import { IMongodbDocument } from './mongodb';

import { IOrganizationEntityDocument } from './organizations';
import { IStore, IStoreGroup } from './stores';
import { EIntegrationCodes } from '../constants';

export interface IApiToken extends IMongodbDocument, IOrganizationEntityDocument {
  integration: EIntegrationCodes;
  active: boolean;
  description: string;
  key: string;
  storeId?: string;
  storeGroupId?: string;
  store?: IStore;
  storeGroup?: IStoreGroup;
}
