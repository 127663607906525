export interface IEmailAccountConfirmationContext {
  name?: string;
  otp?: string;
  organization?: string;
}

export interface IEmailAccountConfirmCreation {
  firstName?: string;
  lastName?: string;
  orgDescription?: string;
}

export interface IEmailCardContext {
  name?: string;
  card?: string;
  message?: string;
}

export interface IEmailCardTransactionContext {
  typeTransaction: string;
  card: string;
  points: number;
}

export class EmailOptions {
  emailTo: string;
  subject?: string;
}
