import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NbDialogRef } from '@nebular/theme';

import { IMedia } from '@loyx/common';

@Component({
  selector: 'media-chooser',
  templateUrl: './media-chooser.component.html',
  styleUrls: ['./media-chooser.component.scss'],
})
export class MediaChooserComponent implements OnInit {
  @Input() mediaFromParent: IMedia;
  @Input() typeAllowed: string;
  @Input() multiFiles: boolean;
  @Input() multiSelect: boolean;

  @Output() assignImageOutput = new EventEmitter<File>();
  @Output() resetImageOutput = new EventEmitter<File>();

  selectedImage = null;
  selectedTab: string = 'Upload';
  typeNotAllowed: boolean = false;

  constructor(private dialogRef: NbDialogRef<MediaChooserComponent>) {}

  ngOnInit(): void {
    if (this.mediaFromParent) this.selectedImage = this.mediaFromParent;
  }
  
  selectTab(event: any) {
    this.selectedTab = event.tabTitle;
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }

  closeDialog() {
    if (
      (this.mediaFromParent && this.selectedImage && this.selectedImage?._id != this.mediaFromParent) ||
      (!this.selectedImage && this.mediaFromParent)
    ) {
      this.selectedImage = this.mediaFromParent;
      this.assignImageOutput.emit(this.selectedImage);
    }
    if (!this.mediaFromParent) this.dialogRef.close();
    else this.dialogRef.close(this.selectedImage);
    if (this.selectedImage == null) this.resetImageToParent();
  }

  handleSelectedImage(event) {
    this.selectedImage = event;
    if (this.selectedImage instanceof Array) {
      this.selectedImage.forEach((image) => {
        this.checkTypeImage(image);
      });
    } else {
      this.checkTypeImage(this.selectedImage);
    }
  }

  checkTypeImage(image) {
    if (image?.contentType.split('/')[0] != this.typeAllowed) this.typeNotAllowed = true;
    else this.typeNotAllowed = false;
  }

  handleUploadedImage(event) {
    this.selectedImage = event;
  }

  resetImageToParent() {
    this.resetImageOutput.emit(this.selectedImage);
  }

  isScreenSmall(): boolean {
    return window.innerWidth < 1100;
  }

  confirm() {
    this.assignImageOutput.emit(this.selectedImage);
    this.dialogRef.close(this.selectedImage);
  }
}
