import { IMongodbDocument } from './mongodb';

import { IConsent } from './agreements';
import { IOrganizationEntityDocument } from './organizations';
import { IStore } from './stores';
import { IUser } from './users';

export interface IAccount extends IMongodbDocument, IOrganizationEntityDocument {
  firstName: string;
  lastName: string;
  // username?: string;
  email: string;
  password?: string;
  active?: boolean;
  verified?: boolean;
  emailVerified?: boolean;
  favouriteStoreId?: string;
  favouriteStore?: IStore;
  otpVerify?: string;
  expireOtpVerifyDate?: string;
  otpReset?: string;
  expireOtpResetDate?: string;
  consents: IConsent[];
  reviewConsents?: boolean;
  lastLogin?: string;
  createdByUserId?: string;
  createdByUser?: IUser;
  disabled?: boolean;
  disablingDate?: string;
}

export interface AccountJwtTokenPayload {
  _id: string;
  email: string;
  organization: string;
}
