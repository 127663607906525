import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  BackendService,
  IBackendQueryParams,
  IBackendQueryPathValues,
  IBackendResponse,
} from '../../@core/utils/backend.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class ApiBackendBaseService<T> {
  public basePath = '';
  private currentOrganizationId: string;

  constructor(public backend: BackendService<T>, private account: AccountService) {
    this.init();
  }

  private init() {
    this.account.organizationChange().subscribe((organization) => {
      this.currentOrganizationId = organization ? organization._id : null;
    });
  }

  getPath(pathValues: IBackendQueryPathValues = {}) {
    let basePath = this.basePath;

    if (this.currentOrganizationId) pathValues.organization = this.currentOrganizationId;

    const pathParams = basePath
      .split('/')
      .map((currentParam) => {
        if (!currentParam.startsWith(':')) return currentParam;

        const paramName = currentParam.slice(1);

        return pathValues[paramName] || null;
      })
      .filter((p) => p);

    return pathParams.join('/');
  }

  queryAll(queryParams?: IBackendQueryParams): Observable<IBackendResponse<T[]>> {
    return this.backend.query(this.getPath(queryParams?.pathValues), queryParams);
  }

  create(item: T, queryParams?: IBackendQueryParams): Observable<IBackendResponse<T>> {
    return this.backend.post(this.getPath(queryParams?.pathValues), item);
  }

  get(queryParams?: IBackendQueryParams): Observable<IBackendResponse<T>> {
    return this.backend.get(this.getPath(queryParams?.pathValues), '', queryParams);
  }

  getOne(_id: string, queryParams?: IBackendQueryParams): Observable<IBackendResponse<T>> {
    return this.backend.get(this.getPath(queryParams?.pathValues), _id, queryParams);
  }

  update(item: Partial<T>, queryParams?: IBackendQueryParams): Observable<IBackendResponse<T>> {
    return this.backend.update(this.getPath(queryParams?.pathValues), '', item);
  }

  updateOne(
    _id: string,
    item: Partial<T>,
    queryParams?: IBackendQueryParams
  ): Observable<IBackendResponse<T>> {
    return this.backend.update(this.getPath(queryParams?.pathValues), _id, item);
  }

  delete(queryParams?: IBackendQueryParams): Observable<IBackendResponse<T>> {
    return this.backend.delete(this.getPath(queryParams?.pathValues), '');
  }

  deleteOne(_id: string, queryParams?: IBackendQueryParams): Observable<IBackendResponse<T>> {
    return this.backend.delete(this.getPath(queryParams?.pathValues), _id);
  }
}
