import * as mongoose from 'mongoose';

import { EAgreementTypes, EConsentSource } from '../constants';
import { DbOrganizationResourceEntity } from './db.entity';

export class AgreementEntity extends DbOrganizationResourceEntity<AgreementEntity> {
  active: boolean;
  type: EAgreementTypes;
  title: string;
  description: string;
  mandatory: boolean;
  retentionPeriod?: number;
  url?: string;
  displayOrder?: number;
  content?: string;
}

export class ConsentEntity {
  agreementId: string | mongoose.Types.ObjectId;
  agreementTitle?: string;
  agreementType?: EAgreementTypes;
  accepted?: boolean;
  date?: string;
  source?: EConsentSource;
  validUntil?: string;
}
