import { EFlyerStatus } from '../constants/flyer.constants';
import { IMedia } from './medias';
import { IMongodbDocument } from './mongodb';
import { IOrganizationEntityDocument } from './organizations';
import { IStore, IStoreGroup } from './stores';
import { IUser } from './users';

export interface IFlyer extends IMongodbDocument, IOrganizationEntityDocument {
  active: boolean;
  title: string;
  description?: string;
  activeFrom?: string;
  activeTo?: string;
  validFrom: string;
  validTo: string;
  bannerMediaId: string;
  bannerMedia?: IMedia;
  mediasIds?: string[];
  medias: IMedia[];
  storesGroupsIds?: string[];
  storesGroups?: IStoreGroup[];
  storesIds?: string[];
  stores?: IStore[];
  status?: EFlyerStatus;
  createdByUserId?: string;
  createdByUser?: IUser;
}
