import { IMongodbDocument } from './mongodb';

import { ECardTransactionSource, ECardTransactionStatus, ECardTransactionType } from '../constants';
import { IAccount } from './accounts';
import { IApiToken } from './api-tokens';
import { ICampaign } from './campaigns';
import { ICard } from './cards';
import { IOrder } from './orders';
import { IOrganizationEntityDocument } from './organizations';
import { IReward } from './rewards';
import { IStore } from './stores';
import { IUser } from './users';

export interface IPurchaseDocument {
  /** Tipo documento (Vendita, Reso) */
  type?: string;
  /** Data del documento */
  date?: string;
  /** Codice gestionale del riferimento documento */
  reference?: string;
  /** Numero gestionale del documento */
  number?: string;
  /** Note aggiuntive documento */
  note?: string;
}

export interface ICardTransaction extends IMongodbDocument, IOrganizationEntityDocument {
  /** Codice carta */
  // cardCode: string;
  /** Tipo transazione */
  type?: ECardTransactionType;
  /** Sorgente da cui è stata effettuata la transazione */
  source: ECardTransactionSource;
  /** Stato della transazione */
  status: ECardTransactionStatus;
  /** Carta */
  cardId?: string;
  card?: ICard;
  /** Importo Transazione - Obbligatorio se del tipo PURCHASE */
  purchaseAmount?: number;
  /** Punti transazione - Obbligatorio se del tipo POINTS */
  points?: number;
  /** Informazioni documento - eventualmente presente con il tipo PURCHASE */
  document?: IPurchaseDocument;
  /** Campagna di riferimento - presente con il tipo POINTS */
  campaignId?: string;
  campaign?: ICampaign;
  /** Negozio in cui è avvenuta la transazione */
  storeId?: string;
  store?: IStore;
  /** Codice cassa del negozio (Point of sale) */
  posCode?: string;
  /** Note aggiuntive transazione */
  note?: string;
  /** Utente backoffice che ha effettuato la transazione */
  userId?: string;
  user?: IUser;
  /** Codice eventuale Errore */
  error?: string;
  /** Descrizione eventuale Errore */
  errorDescription?: string;
  /**  */
  apiTokenId?: string;
  apiToken?: IApiToken;
  /**  */
  accountId?: string;
  account?: IAccount;
  /**  */
  orderId?: string;
  order?: IOrder;
  /**  */
  rewardId?: string;
  reward?: IReward;
  /** Info entità/canale del sistema/api che ha effettuato la transazione */
  // entity?: IEntity*;
  // channel?: IChannel*;
  // channelId?: string;
}

/**
 *
 */
// export type TCardTransaction = Omit<Partial<ICardTransaction>, "campaign" | "store" | "user" | "card"> & {
//     campaign?: string;
//     store?: string;
//     user?: string;
//     card?: string;
// }

// export interface ICardTransactionPopulated extends Omit<ICardTransaction, "card" | "campaign" | "store" | "user"> {
//     card: MongoIdOrDocument<ICard>;
//     campaign?: MongoIdOrDocument<ICampaign>;
//     store?: MongoIdOrDocument<IStore>;
//     user?: MongoIdOrDocument<IUser>;
// }
