import { AccountEntity } from './account.entity';
import { DbResourceEntity } from './db.entity';
import { OrganizationEntity } from './organization.entity';

export class AppLogEntity extends DbResourceEntity {
  action: string;
  success: boolean;
  organizationId?: string;
  accountId?: string;
  info?: string;
  error?: string;
  ip?: string;

  readonly organization?: OrganizationEntity;
  readonly account?: AccountEntity;

  constructor(data?: Omit<AppLogEntity, keyof DbResourceEntity>) {
    super();

    if (data) Object.assign(this, data);
  }
}
