import { Component, Input } from '@angular/core';

@Component({
  selector: 'si-badge-active[active]',
  template: `
    <nb-icon icon="checkmark-circle-2-outline" status="success" *ngIf="active" title="{{labelOn}}"></nb-icon>
    <nb-icon icon="close-circle-outline" status="danger" *ngIf="!active" title="{{labelOff}}"></nb-icon>
  `,
})
export class BadgeActiveComponent {
  @Input('active') active: boolean;

  @Input('labelOn') labelOn: string = 'Attivo';
  @Input('labelOff') labelOff: string = 'Non attivo';
}
