import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbAccessChecker } from '@nebular/security';
import { tap, lastValueFrom, map } from 'rxjs';

import { EBackofficeUserPermission, EUserType, USER_ROLES } from '@loyx/common';
import { AccountService } from './utils';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) {}

  canActivate() {
    return this.authService.isAuthenticated().pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.router.navigate(['account/login']);
        }
      })
    );
  }
}

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) {}

  canActivate() {
    return this.authService.isAuthenticated().pipe(
      map((authenticated) => !authenticated),
      tap((guest) => {
        if (!guest) {
          this.router.navigate(['']);
        }
      })
    );
  }
}

@Injectable()
export class OrganizationGuard implements CanActivate, CanActivateChild {
  constructor(private account: AccountService, private router: Router) {}

  canActivate() {
    return this.hasOrganization();
  }

  canActivateChild() {
    return this.hasOrganization();
  }

  private hasOrganization() {
    if (Boolean(this.account.getOrganization())) return true;

    const userType = this.account.getUser()?.type;

    if (userType === EUserType.ADMIN_USER) {
      this.router.navigate(['amministrazione']);
    } else {
      this.account.logout();
    }

    return false;
  }
}

@Injectable()
export class CheckPermissionGuard implements CanActivate {
  constructor(private account: AccountService, private accessChecker: NbAccessChecker) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.account.getUser();

    if (!user) return false;

    const userPermissions = USER_ROLES.find((r) => user.role == r.id && user.type == r.type).permissions;

    if (!userPermissions[route.data.resource]) return false;

    if (userPermissions[route.data.resource].includes(EBackofficeUserPermission.MANAGE)) return true;

    const granted = await lastValueFrom(
      this.accessChecker.isGranted(route.data.permission, route.data.resource)
    );

    return granted;
  }
}
