export enum ECampaignStatus {
  /** SCONOSCIUTO */
  UNKNOWN = '',
  /** IN CORSO */
  RUNNING = 'RUNNING',
  /** NON ATTIVA */
  INACTIVE = 'INACTIVE',
  /** IN ATTESA */
  PENDING = 'PENDING',
  /** SCADUTA */
  EXPIRED = 'EXPIRED',
}

export const CampaignStatus = [
  {
    id: ECampaignStatus.UNKNOWN,
    description: 'Sconosciuto',
  },
  {
    id: ECampaignStatus.RUNNING,
    description: 'In corso',
  },
  {
    id: ECampaignStatus.INACTIVE,
    description: 'Non attiva',
  },
  {
    id: ECampaignStatus.PENDING,
    description: 'In attesa',
  },
  {
    id: ECampaignStatus.EXPIRED,
    description: 'Terminata',
  },
];

export enum ECampaignRewardType {
  PHYSICAL = 'physical',
  DIGITAL = 'digital',
  //
  GIFT = 'GIFT',
  COUPON_AMOUNT = 'COUPON_AMOUNT',
  COUPON_PERCENT = 'COUPON_PERCENT',
}
