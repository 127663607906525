export enum ENewsStatus {
  /** SCONOSCIUTO */
  UNKNOWN = '',
  /** IN CORSO */
  RUNNING = 'RUNNING',
  /** IN ATTESA */
  PENDING = 'PENDING',
  /** SCADUTA */
  EXPIRED = 'EXPIRED',
}

export const NewsStatusDescriptions = [
  {
    id: ENewsStatus.RUNNING,
    description: 'IN CORSO',
  },
  {
    id: ENewsStatus.PENDING,
    description: 'IN ATTESA',
  },
  {
    id: ENewsStatus.EXPIRED,
    description: 'SCADUTA',
  },
  {
    id: ENewsStatus.UNKNOWN,
    description: 'SCONOSCIUTO',
  },
];
