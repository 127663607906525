import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

export interface IBackendQueryPathValues {
  [path: string]: string;
}

export interface IBackendQueryPagination {
  page?: number;
  perpage?: number;
}

export interface IBackendQuerySorting {
  order?: 'ASC' | 'DESC';
  orderby?: string;
}

export interface IBackendQueryParams {
  pathValues?: IBackendQueryPathValues;
  pagination?: IBackendQueryPagination;
  sorting?: IBackendQuerySorting;
  filters?: any;
  populateFields?: string | string[];
}

export interface IBackendResponse<T> {
  data?: T;
  page?: number;
  totalItems?: number;
  totalPages?: number;
  message?: string | string[];
}

export const SkipErrorMessageHandling = 'X-Skip-Error-Message-Handling';

@Injectable({
  providedIn: 'root',
})
export class BackendService<K> {
  constructor(private http: HttpClient) {}

  query(
    endpoint: string,
    queryParams?: IBackendQueryParams,
    headers?: HttpHeaders,
    skipErrorMessageHandling: boolean = false
  ) {
    let httpParams: HttpParams;

    if (queryParams) {
      let allParams = {};

      if (queryParams.pagination) allParams = Object.assign(allParams, queryParams.pagination);
      if (queryParams.sorting) allParams = Object.assign(allParams, queryParams.sorting);
      if (queryParams.filters) allParams = Object.assign(allParams, queryParams.filters);
      if (queryParams.populateFields) allParams['_populate'] = queryParams.populateFields;

      httpParams = new HttpParams({
        fromObject: allParams,
      });
    }

    headers = headers || new HttpHeaders();

    if (skipErrorMessageHandling) {
      headers.set(SkipErrorMessageHandling, '');
    }

    return this.http
      .get<IBackendResponse<K[]>>(`${environment.apiUrl}/${endpoint}`, {
        params: httpParams || {},
        headers: headers,
      })
      .pipe(first());
  }

  get(
    endpoint: string,
    _id?: string,
    queryParams?: IBackendQueryParams,
    headers?: HttpHeaders,
    skipErrorMessageHandling: boolean = false
  ) {
    let httpParams: HttpParams;

    if (queryParams) {
      let allParams = {};

      if (queryParams.populateFields) allParams['_populate'] = queryParams.populateFields;

      httpParams = new HttpParams({
        fromObject: allParams,
      });
    }

    headers = headers || new HttpHeaders();

    if (skipErrorMessageHandling) {
      headers = headers.set(SkipErrorMessageHandling, '');
    }

    return this.http.get<IBackendResponse<K>>(`${environment.apiUrl}/${endpoint}${_id ? '/' + _id : ''}`, {
      params: httpParams || {},
      headers: headers,
    });
  }

  post(endpoint: string, body: any, headers?: HttpHeaders, skipErrorMessageHandling: boolean = false) {
    headers = headers || new HttpHeaders();

    if (skipErrorMessageHandling) {
      headers = headers.set(SkipErrorMessageHandling, '');
    }

    return this.http.post<IBackendResponse<K>>(`${environment.apiUrl}/${endpoint}`, body, {
      headers: headers,
    });
  }

  update(endpoint: string, _id: string, body: any) {
    return this.http.put<IBackendResponse<K>>(`${environment.apiUrl}/${endpoint}/${_id}`, body);
  }

  delete(endpoint: string, _id: string) {
    return this.http.delete<IBackendResponse<undefined>>(`${environment.apiUrl}/${endpoint}/${_id}`);
  }

  getPdf(endpoint: string, _id?: string): Promise<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    return this.http
      .get<Blob>(`${environment.apiUrl}/${endpoint}`, {
        headers: headers,
        responseType: 'blob' as 'json',
      })
      .toPromise();
  }

  uploadFile(endpoint: string, data: FormData) {
    return this.http.post(`${environment.apiUrl}/${endpoint}`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
