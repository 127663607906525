import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

import { ApiBackendBaseService } from './api-backend-base.service';

import { IBackendQueryPagination, IBackendQueryPathValues, IBackendQuerySorting } from './backend.service';

@Component({
  selector: 'si-items-list-page-base',
  template: ``,
})
export abstract class ItemsListPageBaseComponent<T> implements OnInit {
  @Input('showHeader') showListHeader: boolean = true;

  items: T[];

  totalItems: number;

  pagination: IBackendQueryPagination = {
    perpage: 10,
    page: 1,
  };

  sorting: IBackendQuerySorting = {
    order: 'DESC',
    orderby: '_id',
  };

  filters: any = {};

  populateFields: string | string[];

  loading: boolean = false;

  constructor(public itemsService: ApiBackendBaseService<T>, public toastr: NbToastrService) {}

  ngOnInit(): void {
    this.beforeLoadItems();
    this.load();
    this.afterLoadItems();
  }

  afterLoadItems() {}

  beforeLoadItems() {}

  getPathValues(): IBackendQueryPathValues {
    return {};
  }

  load() {
    this.items = [];
    this.loading = true;
    this.itemsService
      .queryAll({
        pathValues: this.getPathValues(),
        pagination: this.pagination,
        sorting: this.sorting,
        filters: this.filters,
        populateFields: this.populateFields,
      })
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.items = res.data;
          this.totalItems = res.totalItems;
        },
        error: (error) => {
          this.loading = false;
        },
      });
  }

  search() {
    this.resetPagination();
    this.load();
  }

  resetFilters() {
    this.filters = {};
    this.resetPagination();
    this.load();
  }

  resetPagination() {
    this.pagination.page = 1;
  }

  deleteItem(item: T) {
    if (!confirm("Confermi l'eliminazione della risorsa")) return;

    this.itemsService.deleteOne(item['_id']).subscribe({
      next: (res) => {
        this.onDeleteItemSuccess();
      },
      error: (error) => {
        this.onDeleteItemError(error);
      },
    });
  }

  onDeleteItemSuccess() {
    this.toastr.success('Risorsa eliminata con successo', 'Yeah!', { duration: 3000 });
    this.load();
  }

  onDeleteItemError(response: HttpErrorResponse) {
    console.error({ response });

    let errorMessage = response.statusText;

    if (response.error && response.error.message) errorMessage = response.error.message;

    this.toastr.danger('Errore eliminazione risorsa. ' + errorMessage, 'Ooops');
  }
}
