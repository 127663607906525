import { DbOrganizationResourceEntity } from './db.entity';
import { StoreEntity } from './store.entity';
import { StoreGroupEntity } from './store-group.entity';
import { EIntegrationCodes } from '../constants';

export class ApiTokenEntity extends DbOrganizationResourceEntity<ApiTokenEntity> {
  integration: EIntegrationCodes;
  active: boolean;
  description: string;
  readonly key: string;
  storeId?: string;
  storeGroupId?: string;
  readonly store?: StoreEntity;
  readonly storeGroup?: StoreGroupEntity;
}
