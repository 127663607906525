import { IMongodbDocument } from './mongodb';

import { IOrganizationEntityDocument } from './organizations';
import { ICard } from './cards';
import { ECustomerGender } from '../constants';

export interface ICustomer extends IMongodbDocument, IOrganizationEntityDocument {
  firstName: string;
  lastName: string;
  birthDate?: string;
  gender?: ECustomerGender;
  email?: string;
  phone?: string;
  cards?: ICard[];
  fullName?: string;
}
