import { Pipe, PipeTransform } from '@angular/core';

import { ERewardType, RewardTypes } from '@loyx/common';

@Pipe({
  name: 'rewardType',
})
export class RewardTypePipe implements PipeTransform {
  transform(typeId: ERewardType | string, ...args: unknown[]): unknown {
    if (!typeId) return '';

    const type = RewardTypes.find((u) => u.id === typeId);

    return type?.description || typeId;
  }
}
