export enum ERewardType {
  /** FISICO */
  PHYSICAL = 'PHYSICAL',
  /** DIGITALE */
  DIGITAL = 'DIGITAL',
}

export const RewardTypes = [
  {
    id: ERewardType.PHYSICAL,
    description: 'Fisico',
  },
  {
    id: ERewardType.DIGITAL,
    description: 'Digitale',
  },
];

export enum ERewardStockStatus {
  AVAILABLE = 'DISPONIBILE',
  LOWSTOCK = 'IN ESAURIMENTO',
  UNAVAILABLE = 'NON DISPONIBILE',
}

export enum EGiftCardRewardStoreOption {
  ORDER_STORE = 'ORDER_STORE',
  CARD_STORES_GROUP = 'CARD_STORES_GROUP',
}

export const GiftCardRewardStoreOptions = [
  {
    id: EGiftCardRewardStoreOption.ORDER_STORE,
    description: 'Valida solo nel negozio della richiesta premio',
  },
  {
    id: EGiftCardRewardStoreOption.CARD_STORES_GROUP,
    description: 'Valida solo nel gruppo negozi della carta',
  },
];