import { IMongodbDocument } from './mongodb';

import { ICampaign } from './campaigns';
import { ICardTransaction, IPurchaseDocument } from './card-transactions';
import { ICard } from './cards';
import { IStore } from './stores';
import { IUser } from './users';
import { ECardSummaryMovementTrigger, ECardSummaryMovementType } from '../constants';
import { IReward } from './rewards';
import { IOrganizationEntityDocument } from './organizations';

export interface ICardSummaryMovement extends IMongodbDocument {
  /** tipo di trigger che ha fatto partire il movimento */
  trigger: ECardSummaryMovementTrigger;
  /** Tipo del movimento */
  type: ECardSummaryMovementType;
  /** Punti */
  points?: number;
  /** Note */
  note?: string;
  /** Eventuale negozio di riferimento */
  store?: IStore;
  storeId?: string;
  /** Eventuale transazione carta */
  transaction?: ICardTransaction;
  transactionId?: string;
  /** Eventuale importo acquisto */
  purchaseAmount?: number;
  /** Eventuali informazioni documento di acquisto */
  document?: IPurchaseDocument;
  /** Eventuale utente backoffice che ha effettuato il movimento */
  user?: IUser;
  userId?: string;
  /** Eventuale premio riscattato */
  reward?: IReward;
  rewardId?: string;
  /** Eventuale evento */
  // event?: ICardEvent;
  /** Richiesta/Ordine premio catalogo campagna */
  // rewardRequest?: ICampaignRewardRequest;
  // order?: IRewardOrder;
}

export type TCardSummaryMovement = Omit<ICardSummaryMovement, 'store' | 'transaction' | 'user'> & {
  store?: string;
  transaction?: string;
  user?: string;
};

/**
 *
 */
export interface ICardSummary extends IMongodbDocument, IOrganizationEntityDocument {
  /** Carta */
  cardId: string;
  card?: ICard; // MongoIdOrDocument<ICard>;
  /** Campagna di riferimento  */
  campaignId: string;
  campaign?: ICampaign; //MongoIdOrDocument<ICampaign>;
  /** Importo speso nella campagna di riferimento */
  purchasesAmount?: number;
  /** Saldo punti campagna di riferimento  */
  points?: number;
  /** Movimenti carta della campagna di riferimento */
  movements?: ICardSummaryMovement[];
  /** Premio riscattato */
  rewardId?: string;
  reward?: IReward;
}

/**
 *
 */
export type TCardSummary = Omit<Partial<ICardSummary>, 'card' | 'campaign'> & {
  card: string;
  campaign: string;
};
