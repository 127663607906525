import { Pipe, PipeTransform } from '@angular/core';

const BYTE_UNITS = ['B', 'kB', 'MB'];

@Pipe({
  name: 'bytes',
})
export class FileBitesPipe implements PipeTransform {
  transform(bytes: number = 0, precision: number = 2): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    return bytes.toFixed(+precision) + ' ' + BYTE_UNITS[unitIndex];
  }
}
