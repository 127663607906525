import { Pipe, PipeTransform } from '@angular/core';
import { ActionDescription, EAppUserAction } from '@loyx/common';

@Pipe({
  name: 'accountAction',
})
export class AccountActionPipe implements PipeTransform {
  transform(actionId: EAppUserAction | string, ...args: unknown[]): unknown {
    if (!actionId) return '';

    const action = ActionDescription.find((u) => u.id === actionId);

    return action ? action.description : action.id;
  }
}
