import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'si-table-list-header',
  styleUrls: ['./table-list-header.component.scss'],
  template: `
    <nb-card>
      <nb-card-header>
        <div class="row no-gutters align-items-center">
            <div class="col-sm-6 text-left">
                <span class="header-title" *ngIf="title" [innerHtml]="title"></span>
                <span class="header-subtitle" *ngIf="subtitle" [innerHtml]="subtitle"></span>
            </div>

            <div class="col-sm-6 text-right">
                <button nbButton ghost status="primary" (click)="reload()" *ngIf="reloadEnabled" [nbSpinner]="reloading">
                  <nb-icon icon="refresh-outline"></nb-icon> Aggiorna
                </button>

                <ng-content #buttonsRight></ng-content>           
            </div>
        </div>
    </nb-card-header>
  </nb-card>
  `,
})
export class TableListHeaderComponent implements OnInit {
  @Input('title') title: string;
  @Input('subtitle') subtitle: string;

  @Input('reload') reloadEnabled: boolean | string;
  @Input('reloading') reloading: boolean;

  @Output() onReload = new EventEmitter<any>();

  reload() {
    this.onReload.emit();
  }

  ngOnInit() {
    this.reloadEnabled = Boolean(this.reloadEnabled !== undefined && (this.reloadEnabled === true || this.reloadEnabled === 'true' || this.reloadEnabled === ''));
  }
}
