import { Component } from '@angular/core';

import { environment } from 'apps/backoffice-frontend/src/environments/environment';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div>
      <span class="version">v.{{appVersion}}</span>

      <span class="created-by">
        Created with ❤️ by <b><a href="https://www.siretail.it" target="_blank">SI RETAIL</a></b> 2023 &copy;
      </span>
    </div>

    <div class="socials">
      <a href="https://www.siretail.it" target="_blank" class="ion ion-globe">
        <nb-icon icon="globe-outline"></nb-icon>
      </a>
      <!-- <a href="#" target="_blank" class="ion ion-social-github"></a>        
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a> -->
    </div>
  `,
})
export class FooterComponent {
  appVersion: string = environment.version;
}
