import { Component, ViewEncapsulation } from '@angular/core';
import { AppUpdateService } from '../../../@core/utils/app-update.service';

@Component({
    selector: 'app-update-alert',
    template: `
        <div *ngIf="appUpdateService.isNewVersionAvailable">
            <nb-alert accent="success">
             <div class="d-flex align-items-center justify-content-between">
                <div>
                    <strong>Nuovo aggiornamento disponibile</strong> 
                    <br>
                    Ricarica la pagina per applicare le ultime modifiche.
                </div>

                <button nbButton size="tiny" status="success" (click)="refreshPage()">
                    <nb-icon icon="refresh-outline" pack="eva"></nb-icon>
                    Ricarica pagina
                </button>
             </div>
            </nb-alert>
        </div>
    `,
    styles: [``],
    encapsulation: ViewEncapsulation.None
})
export class AppUpdateAlertComponent {
    constructor(
        public appUpdateService: AppUpdateService
    ) { }

    refreshPage(): void {
        this.appUpdateService.applyUpdate();
    }
}