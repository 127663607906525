import { Injectable } from '@angular/core';
import { NbAuthService, NbAuthToken, NbTokenService } from '@nebular/auth';
import { BehaviorSubject, filter, Observable, share } from 'rxjs';

import { IOrganization, IUser, UserJwtTokenPayload } from '@loyx/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  protected KEY_USER = 'si-user';
  protected KEY_ORGANIZATION = 'si-organization';

  protected user$: BehaviorSubject<IUser> = new BehaviorSubject(null);
  protected organization$: BehaviorSubject<IOrganization> = new BehaviorSubject(null);

  constructor(
    private authService: NbAuthService,
    private tokenService: NbTokenService,
    private router: Router
  ) {
    this.publishStoredUser();
    this.publishStoredOrganization();

    this.authService.onTokenChange().subscribe((token: NbAuthToken) => {
      if (token.isValid()) {
        const payload: UserJwtTokenPayload = token.getPayload();

        // TEMP - TODO
        this.setUser({
          _id: payload.userid,
          organizationId: payload.organizationId,
          organization: payload.organization,
          type: payload.type,
          role: payload.role,
          active: true,
          firstName: payload.username,
          username: payload.username,
          email: payload.email,
        });

        return;
      }

      console.error("AccountService onTokenChange - routing to account/login (?)");
      
      this.setUser(null);
      // this.router.navigate(['account/login']);
    });
  }

  logout() {
    this.tokenService.clear().subscribe({
      complete: () => {
        this.removeUser();
        this.removeOrganization();
        this.router.navigate(['account/login']);
      },
    });
  }

  userChange(): Observable<IUser> {
    return this.user$.pipe(
      filter((value) => !!value),
      share()
    );
  }

  getUser(): IUser {
    return JSON.parse(localStorage.getItem(this.KEY_USER));
  }

  setUser(user: IUser) {
    localStorage.setItem(this.KEY_USER, JSON.stringify(user));
    this.publishStoredUser();
  }

  removeUser() {
    localStorage.removeItem(this.KEY_USER);
    this.publishStoredUser();
  }

  protected publishStoredUser() {
    this.user$.next(this.getUser());
  }

  organizationChange(): Observable<IOrganization> {
    return this.organization$.pipe(
      filter((value) => !!value),
      share()
    );
  }

  getOrganization(): IOrganization {
    return JSON.parse(localStorage.getItem(this.KEY_ORGANIZATION));
  }

  setOrganization(organization: IOrganization) {
    localStorage.setItem(this.KEY_ORGANIZATION, JSON.stringify(organization));
    this.publishStoredOrganization();
  }

  removeOrganization() {
    localStorage.removeItem(this.KEY_ORGANIZATION);
    this.publishStoredOrganization();
  }

  protected publishStoredOrganization() {
    this.organization$.next(this.getOrganization());
  }
}
