import { ENewsStatus } from '../constants';
import { DbOrganizationResourceEntity } from './db.entity';
import { MediaEntity } from './media.entity';
import { UserEntity } from './user.entity';

export class NewsEntity extends DbOrganizationResourceEntity<NewsEntity> {
  /** Titolo della news */
  title: string;
  /** Secondo titolo della news */
  subTitle?: string;
  /** News Attiva/Non Attiva */
  active: boolean;
  /** Corpo della news */
  body: string;
  /** Data della news */
  date: string;
  /** News attivata/disattivata */
  status?: ENewsStatus;
  /** News attiva dal */
  activeFrom?: string;
  /** News attiva fino al */
  activeTo?: string;
  /** Media della news */
  mediaId?: string;
  /** Utente che ha creato la card */
  createdByUserId?: string;

  readonly createdByUser?: UserEntity;
  readonly media?: MediaEntity;
}
