import { Pipe, PipeTransform } from '@angular/core';
import { EOrderDeliveryStatus, OrderDeliveryStatuses } from '@loyx/common';

@Pipe({
  name: 'orderDeliveryStatus',
})
export class orderDeliveryStatusPipe implements PipeTransform {
  transform(statusId: EOrderDeliveryStatus | string, ...args: unknown[]): unknown {
    if (!statusId) return '';

    const status = OrderDeliveryStatuses.find((u) => u.id === statusId);

    return status ? status.description : statusId;
  }
}
