import { Pipe, PipeTransform } from '@angular/core';

import {
  EGiftCardTransactionStatus,
  EGiftCardTransactionType,
  GiftCardTransactionStatusDescriptions,
  GiftCardTransactionTypeDescriptions,
} from '@loyx/common';

@Pipe({
  name: 'giftCardTransactionType',
})
export class GiftCardTransactionTypePipe implements PipeTransform {
  transform(statusId: EGiftCardTransactionType | string, ...args: unknown[]): unknown {
    if (!statusId) return '';

    const status = GiftCardTransactionTypeDescriptions.find((u) => u.id === statusId);

    return status ? status.description : statusId;
  }
}

@Pipe({
  name: 'giftCardTransactionStatus',
})
export class GiftCardTransactionStatusPipe implements PipeTransform {
  transform(statusId: EGiftCardTransactionStatus | string, ...args: unknown[]): unknown {
    if (!statusId) return '';

    const status = GiftCardTransactionStatusDescriptions.find((u) => u.id === statusId);

    return status ? status.description : statusId;
  }
}
