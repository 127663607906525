import { Component, Input } from '@angular/core';
import { IMedia } from '@loyx/common';

@Component({
  selector: 'media-preview',
  template: `
    <div
      class="mediaContainer d-flex border border-5"
      [ngStyle]="{ height: height + 'px', width: width + 'px' }"
    >
      <div
        *ngIf="media?.url"
        [style.backgroundImage]="'url(' + media?.url + ')'"
        class="image-item-selected"
      ></div>
      <div
        *ngIf="!media?.url"
        [style.backgroundImage]="'url(assets/images/loyx-no-media-250.png)'"
        class="image-item-selected"
      ></div>
    </div>
  `,
  styles: [
    `
      .mediaContainer {
        background-color: #fafafa;
        .image-item-selected {
          max-width: 100%;
          border-radius: 4px;
          aspect-ratio: 1/1;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    `,
  ],
})
export class MediaPreviewComponent {
  @Input() media: IMedia;
  @Input() width: number;
  @Input() height: number;
}
