import { EOrderDeliveryStatus, EOrderSource, EOrderStatus } from '../constants';
import { AccountEntity } from './account.entity';
import { CampaignEntity } from './campaign.entity';
import { CardEntity } from './card.entity';
import { DbOrganizationResourceEntity } from './db.entity';
import { GiftCardEntity } from './giftcard.entity';
import { RewardEntity } from './rewards.entity';
import { StoreEntity } from './store.entity';
import { UserEntity } from './user.entity';

export class OrderEntity extends DbOrganizationResourceEntity<OrderEntity> {
  campaignId: string;
  cardId: string;
  rewardId: string;
  source: EOrderSource;
  date?: string;
  points?: number;
  status?: EOrderStatus;
  deliveryStatus?: EOrderDeliveryStatus;
  storeId?: string;
  note?: string;
  giftCardId?: string;
  accountId?: string;
  userId?: string;
  refunded?: boolean;

  readonly campaign?: CampaignEntity;
  readonly card?: CardEntity;
  readonly user?: UserEntity;
  readonly account?: AccountEntity;
  readonly reward?: RewardEntity;
  readonly giftCard?: GiftCardEntity;
  readonly store?: StoreEntity;
}
